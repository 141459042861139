import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, Table, Button, InputGroup, Form } from 'react-bootstrap';

function FormularContainerFachaufsicht() {
  const [eigeneFormulare, setEigeneFormulare] = useState([]);
  const [zuPruefendeFormulare, setZuPruefendeFormulare] = useState([]);
  const [endabgegebeneFormulare, setEndabgegebeneFormulare] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // Suchbegriff
  const [erstellerFilter, setErstellerFilter] = useState(''); // Filter für Ersteller
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token'); // Token aus dem localStorage holen

    if (!token) {
      navigate('/login'); // Wenn kein Token vorhanden ist, zum Login weiterleiten
    }

    // Anfrage für eigene Formulare
    axios.get(`${process.env.REACT_APP_API_URL}/api/forms/eigene`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setEigeneFormulare(response.data || []);
    })
    .catch(error => {
      console.error('Fehler beim Laden der eigenen Formulare:', error);
      setError('Fehler beim Laden der eigenen Formulare');
    });

    // Anfrage für zu prüfende Formulare
    axios.get(`${process.env.REACT_APP_API_URL}/api/forms/pruefen`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setZuPruefendeFormulare(response.data || []);
    })
    .catch(error => {
      console.error('Fehler beim Laden der zu prüfenden Formulare:', error);
      setError('Fehler beim Laden der zu prüfenden Formulare');
    });

    // Anfrage für endabgegebene Formulare
    axios.get(`${process.env.REACT_APP_API_URL}/api/forms/endabgegeben`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setEndabgegebeneFormulare(response.data || []);
    })
    .catch(error => {
      console.error('Fehler beim Laden der endabgegebenen Formulare:', error);
      setError('Fehler beim Laden der endabgegebenen Formulare');
    });
  }, []);

  // Funktion zur Filterung nach Suchbegriff und Ersteller
  const filterFormulare = (formulare) => {
    return formulare.filter((formular) => {
      const matchesSearchQuery =
        formular.fahrer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formular.kennzeichen.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesErsteller =
        erstellerFilter === '' || formular.erstellt_von.toLowerCase().includes(erstellerFilter.toLowerCase());

      return matchesSearchQuery && matchesErsteller;
    });
  };

  return (
    <div className="container mt-5">
      <Card className="mx-auto" style={{ maxWidth: '1000px' }}>
        <Card.Header>
          <h2>Fachaufsicht - Formulare</h2>
          {/* Filter und Suche */}
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Suche nach Name oder Kennzeichen"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Form.Control
              type="text"
              placeholder="Filter nach Ersteller"
              value={erstellerFilter}
              onChange={(e) => setErstellerFilter(e.target.value)}
            />
          </InputGroup>
        </Card.Header>
        <Card.Body>
          {/* Eigene Formulare */}
          <h3>Eigene Formulare</h3>
          {eigeneFormulare.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Fahrer Name</th>
                  <th>Kennzeichen</th>
                  <th>Verstoß Art</th>
                  <th>Ersteller</th> {/* Neue Spalte für Ersteller */}
                  <th>Status</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {filterFormulare(eigeneFormulare).map((formular) => (
                  <tr key={formular.id}>
                    <td>{formular.fahrer_name}</td>
                    <td>{formular.kennzeichen}</td>
                    <td>{formular.verstoß_art}</td>
                    <td>{formular.erstellt_von}</td> {/* Ersteller anzeigen */}
                    <td>
                      <span className={`badge bg-${formular.status === 'Erstellt' ? 'primary' : formular.status === 'Zur Prüfung' ? 'warning' : 'success'}`}>
                        {formular.status}
                      </span>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm" // Button in kleiner Größe
                        onClick={() => navigate(`/formular-detail-fachaufsicht/${formular.id}`)}
                      >
                        Formular ansehen
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Keine eigenen Formulare vorhanden.</p>
          )}

          {/* Zu prüfende Formulare */}
          <h3>Zu prüfende Formulare</h3>
          {zuPruefendeFormulare.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Fahrer Name</th>
                  <th>Kennzeichen</th>
                  <th>Verstoß Art</th>
                  <th>Ersteller</th> {/* Neue Spalte für Ersteller */}
                  <th>Status</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {filterFormulare(zuPruefendeFormulare).map((formular) => (
                  <tr key={formular.id}>
                    <td>{formular.fahrer_name}</td>
                    <td>{formular.kennzeichen}</td>
                    <td>{formular.verstoß_art}</td>
                    <td>{formular.erstellt_von}</td> {/* Ersteller anzeigen */}
                    <td>
                      <span className="badge bg-warning">Zur Prüfung</span>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm" // Button in kleiner Größe
                        onClick={() => navigate(`/formular-detail-fachaufsicht/${formular.id}`)}
                      >
                        Formular ansehen
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Keine zu prüfenden Formulare vorhanden.</p>
          )}

          {/* Endabgegebene Formulare */}
          <h3>Endabgegebene Formulare</h3>
          {endabgegebeneFormulare.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Fahrer Name</th>
                  <th>Kennzeichen</th>
                  <th>Verstoß Art</th>
                  <th>Ersteller</th> {/* Neue Spalte für Ersteller */}
                  <th>Status</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {filterFormulare(endabgegebeneFormulare).map((formular) => (
                  <tr key={formular.id}>
                    <td>{formular.fahrer_name}</td>
                    <td>{formular.kennzeichen}</td>
                    <td>{formular.verstoß_art}</td>
                    <td>{formular.erstellt_von}</td> {/* Ersteller anzeigen */}
                    <td>
                      <span className="badge bg-success">Endabgegeben</span>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm" // Button in kleiner Größe
                        onClick={() => navigate(`/formular-detail-fachaufsicht/${formular.id}`)}
                      >
                        Formular ansehen
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Keine endabgegebenen Formulare vorhanden.</p>
          )}

          {error && <div className="alert alert-danger">{error}</div>}
        </Card.Body>
        <Card.Footer>
          <Button className="btn btn-secondary" onClick={() => navigate('/home')}>
            Zurück zur Startseite
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default FormularContainerFachaufsicht;
