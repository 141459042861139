import React from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';

function Home() {
  const navigate = useNavigate();
  
  // Benutzerinfos aus dem localStorage holen
  const user = JSON.parse(localStorage.getItem('user')) || {}; // Absicherung f�r leere oder ung�ltige Werte

if (!user.username || !user.role) {
  return <Navigate to="/login" />;
}

  const handleFormularContainer = () => {
    if (user.role === 'Fachaufsicht') {
      navigate('/formular-container-fachaufsicht');
    } else {
      navigate('/formular-container-kollegen');
    }
  };
  
  // Funktion zur Navigation basierend auf der Rolle
  const handleSettingsOrAdminPanel = () => {
    if (user.role === 'Admin') {
      navigate('/admin-panel'); // Admin wird zum AdminPanel weitergeleitet
    } else {
      navigate('/user-settings'); // Normale Benutzer zur UserSettings-Seite
    }
  };

  return (
    <div className="container mt-5">
      <Card className="text-center mx-auto" style={{ maxWidth: '1000px' }}>
        <Card.Header>
          <h2>Willkommen, {user.username}!</h2>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            OE: <span className="badge bg-info">{user.department}</span> | 
            Rolle: <span className="badge bg-secondary">{user.role}</span>
          </Card.Text>

          <div className="row justify-content-center mt-4">
            <div className="col-md-4">
              <Button
                variant="primary"
                className="w-100 mb-3"
                onClick={() => navigate('/formular-auswahl')}
              >
                Neues Formular erstellen
              </Button>
              <Button
                variant="secondary"
                className="w-100 mb-3"
                onClick={handleFormularContainer}
              >
                Eingereichte Formulare
              </Button>
              {/* Button zur Verwaltung einf�gen */}
              <Button
                variant="info"
                className="w-100 mb-3"
                onClick={handleSettingsOrAdminPanel} // Aufruf der Funktion
              >
                Verwaltung
              </Button>
              <Button
                variant="danger"
                className="w-100 mb-3"
                onClick={() => navigate('/logout')}
              >
                Abmelden
              </Button>
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Angemeldet als: {user.username}</small>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default Home;