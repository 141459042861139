import React, { useState, useEffect } from 'react';
import { Button, Form, Tab, Tabs, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas'; // Signaturfeld hinzufügen

const UserSettings = () => {
  const [activeTab, setActiveTab] = useState('favoriten');
  const [darkMode, setDarkMode] = useState(false);
  const [favoriten, setFavoriten] = useState([]);
  const [benachrichtigung, setBenachrichtigung] = useState('push');
  const [signaturePath, setSignaturePath] = useState(null);
  const [newSignature, setNewSignature] = useState(null); // Für das Unterschriftenfeld
  const [generatedSignature, setGeneratedSignature] = useState(''); // Für die Unterschriftengenerierung
  const navigate = useNavigate();

  // Hole den Benutzername aus dem localStorage
  const user = JSON.parse(localStorage.getItem('user'));
  const username = user?.username; // Stelle sicher, dass der Benutzername vorhanden ist

  useEffect(() => {
    if (username) {
      // Laden der Nutzereinstellungen beim Initialisieren der Seite
      const loadUserProfile = async () => {
        try {
          // Benutzerprofil laden (Dark Mode, Benachrichtigungseinstellungen, Signaturpfad)
          const response = await axios.get(`/api/user-profile/${username}`);
          const profile = response.data;
          
          setDarkMode(profile.dark_mode === 1);
          setBenachrichtigung(profile.benachrichtigungseinstellungen || 'push');
          setSignaturePath(profile.signatur_path || null);

          // Favoriten laden
          const favoritenResponse = await axios.get(`/api/user-favoriten/${username}`);
          if (favoritenResponse.data.length === 0) {
            setFavoriten([]); // Keine Favoriten angelegt
          } else {
            setFavoriten(favoritenResponse.data); // Favoriten setzen
          }
        } catch (error) {
          console.error('Fehler beim Laden des Benutzerprofils oder der Favoriten:', error);
        }
      };
      loadUserProfile();
    }
  }, [username]);

  // Favoriten speichern
  const saveFavoriten = async () => {
    try {
      await axios.post('/api/user-favoriten', {
        user_name: username,
        favoriten: favoriten.map(fav => fav.key) // Sende nur die IDs
      });
      alert('Favoriten erfolgreich gespeichert!');
    } catch (error) {
      console.error('Fehler beim Speichern der Favoriten:', error);
    }
  };

  // Dark Mode speichern
const saveDarkMode = async () => {
  try {
    await axios.post('/api/user-profile', {
      user_name: username,
      dark_mode: darkMode ? 1 : 0,
      benachrichtigungseinstellungen: benachrichtigung,  // Aktueller Wert der Benachrichtigung mitliefern
      signatur_path: signaturePath  // Signatur mitliefern, falls vorhanden
    });
    alert('Dark Mode erfolgreich gespeichert!');
  } catch (error) {
    console.error('Fehler beim Speichern des Dark Modes:', error);
  }
};

// Benachrichtigungseinstellungen speichern
const saveBenachrichtigung = async () => {
  try {
    await axios.post('/api/user-profile', {
      user_name: username,
      benachrichtigungseinstellungen: benachrichtigung,
      dark_mode: darkMode ? 1 : 0,  // Aktueller Wert des Dark Modes mitliefern
      signatur_path: signaturePath  // Signatur mitliefern, falls vorhanden
    });
    alert('Benachrichtigungseinstellungen erfolgreich gespeichert!');
  } catch (error) {
    console.error('Fehler beim Speichern der Benachrichtigungseinstellungen:', error);
  }
};

  // Unterschrift hochladen
  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('signature', file);
    formData.append('userType', 'beamter');

    try {
      const response = await axios.post('/api/save-signature', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSignaturePath(response.data.signaturePath);
      alert('Unterschrift erfolgreich hochgeladen!');
    } catch (error) {
      console.error('Fehler beim Hochladen der Unterschrift:', error);
    }
  };

  // Unterschrift generieren (aus Text)
  const generateSignatureFromText = () => {
    setGeneratedSignature(generatedSignature); // In eine Signatur umwandeln (könnte mit einem externen Service geschehen)
  };

return (
    <Container className="mt-5">
      <Card className="p-4">
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-4"
        >
          <Tab eventKey="favoriten" title="Favoriten">
            <h3 className="mb-3">Favoriten verwalten</h3>
            {favoriten.length === 0 ? (
              <p>Du hast noch keine Favoriten angelegt.</p>
            ) : (
              <ul>
                {favoriten.map((fav, index) => (
                  <li key={index}>{fav.key} - {fav.title}</li>
                ))}
              </ul>
            )}
            <div className="mt-4">
              <Button onClick={saveFavoriten}>Favoriten speichern</Button>
            </div>
          </Tab>

          <Tab eventKey="darkmode" title="Dark Mode">
            <h3 className="mb-3">Dark Mode</h3>
            <Form.Check
              type="switch"
              label="Dark Mode aktivieren"
              checked={darkMode}
              onChange={(e) => setDarkMode(e.target.checked)}
              className="mb-3"
            />
            <Button onClick={saveDarkMode}>Dark Mode speichern</Button>
          </Tab>

          <Tab eventKey="signature" title="Unterschrift">
            <h3 className="mb-3">Unterschrift verwalten</h3>
            {signaturePath ? (
              <div>
                <p>Aktuelle Unterschrift:</p>
                <img src={signaturePath} alt="Signatur" style={{ width: '100%', maxWidth: '300px' }} />
              </div>
            ) : (
              <p>Keine Unterschrift hinterlegt.</p>
            )}
            <Form.Group className="mt-4">
              <Form.Label>Neue Unterschrift hochladen:</Form.Label>
              <Form.Control type="file" onChange={handleSignatureUpload} />
            </Form.Group>
            <hr />
            <h5>Signaturfeld</h5>
            <div
              className="signature-container mt-3 mb-3"
              style={{
                border: '1px solid #ccc',
                width: '100%',
                height: '150px',
                maxWidth: '500px',
                margin: '0 auto'
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 150,
                  className: 'sigCanvas'
                }}
                ref={(ref) => setNewSignature(ref)}
              />
            </div>
            <Button variant="secondary" onClick={() => setNewSignature(null)} className="mb-3">
              Unterschrift löschen
            </Button>
            <hr />
            <h5>Unterschrift generieren</h5>
            <Form.Group className="mb-3">
              <Form.Label>Name zur Unterschriftengenerierung</Form.Label>
              <Form.Control
                type="text"
                value={generatedSignature}
                onChange={(e) => setGeneratedSignature(e.target.value)}
                className="mb-3"
              />
              <Button onClick={generateSignatureFromText}>Unterschrift generieren</Button>
            </Form.Group>
          </Tab>

          <Tab eventKey="benachrichtigung" title="Benachrichtigung">
            <h3 className="mb-3">Benachrichtigungseinstellungen</h3>
            <Form.Group className="mb-3">
              <Form.Label>Bevorzugte Benachrichtigungsart</Form.Label>
              <Form.Control
                as="select"
                value={benachrichtigung}
                onChange={(e) => setBenachrichtigung(e.target.value)}
                className="mb-3"
              >
                <option value="push">Push</option>
                <option value="email">E-Mail</option>
                <option value="beides">Beides</option>
                <option value="keine">Keine Benachrichtigungen</option>
              </Form.Control>
            </Form.Group>
            <Button onClick={saveBenachrichtigung}>Benachrichtigungseinstellungen speichern</Button>
          </Tab>
        </Tabs>
        <div className="text-center mt-4">
          <Button variant="secondary" onClick={() => navigate('/home')}>
            Zurück
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default UserSettings;