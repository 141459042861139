import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, Table, Button, Form, InputGroup } from 'react-bootstrap';

function FormularContainerKollegen() {
  const [eigeneFormulare, setEigeneFormulare] = useState([]);
  const [filteredFormulare, setFilteredFormulare] = useState([]); // Gefilterte Liste
  const [error, setError] = useState('');
  const [statusFilter, setStatusFilter] = useState(''); // Filter für Status
  const [searchQuery, setSearchQuery] = useState(''); // Suchbegriff
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token'); // Token aus dem localStorage holen

    if (!token) {
      navigate('/login'); // Wenn kein Token vorhanden ist, zum Login weiterleiten
    }

    // Anfrage für eigene Formulare
    axios.get(`${process.env.REACT_APP_API_URL}/api/forms/eigene`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setEigeneFormulare(response.data || []);
      setFilteredFormulare(response.data || []); // Initiale Filterung
    })
    .catch(error => {
      console.error('Fehler beim Laden der Formulare:', error);
      setError('Fehler beim Laden der Formulare');
    });
  }, []);

  // Filter nach Status und Suchbegriff
  useEffect(() => {
    let filtered = eigeneFormulare;

    if (statusFilter) {
      filtered = filtered.filter(formular => formular.status === statusFilter);
    }

    if (searchQuery) {
      filtered = filtered.filter(formular =>
        formular.fahrer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        formular.kennzeichen.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredFormulare(filtered);
  }, [statusFilter, searchQuery, eigeneFormulare]);

  return (
    <div className="container mt-5">
      <Card className="mx-auto" style={{ maxWidth: '1000px' }}>
        <Card.Header>
          <h2>Eigene Formulare</h2>
          {/* Filter und Suche */}
          <InputGroup className="mb-3">
           <Form.Control
              type="text"
              placeholder="Suchen nach Name oder Kennzeichen"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Form.Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              aria-label="Status filtern"
            >
              <option value="">Alle Status</option>
              <option value="Erstellt">Erstellt</option>
              <option value="Zur Prüfung">Zur Prüfung</option>
              <option value="Endabgegeben">Endabgegeben</option>
            </Form.Select>
          </InputGroup>
        </Card.Header>
        <Card.Body>
          {filteredFormulare.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Fahrer Name</th>
                  <th>Kennzeichen</th>
                  <th>Verstoß Art</th>
                  <th>Status</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {filteredFormulare.map((formular) => (
                  <tr key={formular.id}>
                    <td>{formular.fahrer_name}</td>
                    <td>{formular.kennzeichen}</td>
                    <td>{formular.verstoß_art}</td>
                    <td>
                      <span className={`badge bg-${formular.status === 'Erstellt' ? 'primary' : formular.status === 'Zur Prüfung' ? 'warning' : 'success'}`}>
                        {formular.status}
                      </span>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => navigate(`/formular-detail-kollegen/${formular.id}`)}
                      >
                        Formular ansehen
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>Keine Formulare vorhanden.</p>
          )}

          {error && <div className="alert alert-danger">{error}</div>}
        </Card.Body>
        <Card.Footer>
          <Button className="btn btn-secondary" onClick={() => navigate('/home')}>
            Zurück zur Startseite
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default FormularContainerKollegen;
