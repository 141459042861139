import React, { useState } from 'react';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';

// Beweismittel-Auswahl Modal-Komponente
function BeweismittelSelectionModal({ show, handleClose, handleSave }) {
  const [selectedBeweismittel, setSelectedBeweismittel] = useState({ bereich1: '', bereich2: '' });
  const [activeTab, setActiveTab] = useState('bereich1'); // Aktiver Tab für die 2 Bereiche

  // Beispielwerte für die beiden Bereiche, mit Schlüsseln (Unterstrich zur Differenzierung)
  const bereich1Werte = [
    { name: 'Alcotest 7110 Evidential', key: '1' },
    { name: 'HLMG Riegl FG 21-P', key: '2' },
    { name: 'HLMG Riegl LR90-235/P', key: '3' },
    { name: 'HLMG LTI 20.20', key: '4' },
    { name: 'Abstandsmessung', key: '5' },
    { name: 'Messung durch Nachfahren', key: '6_1' }, // Beispiel mit Unterstrich
  ];

  const bereich2Werte = [
    { name: 'Messung im Spiegelmessverfahren', key: 'A_1' },
    { name: 'Messung im Funkstoppmessverfahren', key: 'B_2' },
    { name: 'Sonstiges', key: 'C_1' }
  ];

  // Mapping von Bereich 1 auf zulässige Optionen in Bereich 2
  const mapping = {
    '1': '*',  // Alle Werte sind erlaubt
    '2': '*', 
    '3': ['A_1', 'B_2'], // Erlaubte Schlüssel
    '4': ['B_2', 'C_1'], 
    '5': ['A_1'],
    '6_1': ['C_1']
  };

  // Funktion zum Filtern von Bereich 2 basierend auf der Auswahl in Bereich 1
  const getFilteredBereich2 = () => {
    if (!selectedBeweismittel.bereich1) return bereich2Werte;

    const allowedKeys = mapping[selectedBeweismittel.bereich1.split('_')[0]] || [];

    if (allowedKeys === '*') {
      return bereich2Werte;
    }

    return bereich2Werte.filter((item) => allowedKeys.includes(item.key.split('_')[0])); // Filtere basierend auf dem Teil vor dem Unterstrich
  };

  // Funktion zum Verarbeiten der Auswahl
  const handleBeweismittelClick = (bereich, value) => {
    setSelectedBeweismittel((prevSelected) => {
      const newValue = prevSelected[bereich] === value ? '' : value;

      // Wechseln zum nächsten Tab, wenn ein neuer Wert ausgewählt wurde
      if (newValue !== '' && bereich === 'bereich1') {
        setActiveTab('bereich2');
      }

      return { 
        ...prevSelected, 
        [bereich]: newValue 
      };
    });
  };

  // Funktion zum Schließen des Modals
  const handleCloseModal = () => {
    setActiveTab('bereich1'); // Zurück zum ersten Tab
    handleClose(); // Schließt das Modal
  };

  // Speichern der Beweismittel-Auswahl
  const handleSaveClick = () => {
    const finalBeweismittel = `${selectedBeweismittel.bereich1.split('_')[0]}${selectedBeweismittel.bereich2.split('_')[0]}`; // Extrahiere den Teil vor dem Unterstrich
    handleSave(finalBeweismittel);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Beweismittel-Auswahl</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
          <Tab eventKey="bereich1" title="Beweismittel Kategorie 1">
            <div className="d-flex flex-wrap">
              {bereich1Werte.map((item) => (
                <Button
                  key={item.key}
                  variant={selectedBeweismittel.bereich1 === item.key ? "primary" : "outline-primary"}
                  onClick={() => handleBeweismittelClick('bereich1', item.key)}
                  className={`m-2 ${selectedBeweismittel.bereich1 === item.key ? 'active' : ''}`}
                >
                  {item.name} ({item.key.split('_')[0]}) {/* Zeige nur den Teil vor dem Unterstrich an */}
                </Button>
              ))}
            </div>
          </Tab>
          <Tab eventKey="bereich2" title="Beweismittel Kategorie 2">
            <div className="d-flex flex-wrap">
              {getFilteredBereich2().map((item) => (
                <Button
                  key={item.key}
                  variant={selectedBeweismittel.bereich2 === item.key ? "primary" : "outline-primary"}
                  onClick={() => handleBeweismittelClick('bereich2', item.key)}
                  className={`m-2 ${selectedBeweismittel.bereich2 === item.key ? 'active' : ''}`}
                >
                  {item.name} ({item.key.split('_')[0]}) {/* Zeige nur den Teil vor dem Unterstrich an */}
                </Button>
              ))}
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>Abbrechen</Button>
        <Button variant="primary" onClick={handleSaveClick}>Übernehmen</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BeweismittelSelectionModal;
