import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FormularAuswahl.css';  // Deine CSS Datei

function FormularAuswahl() {
  const [formularArten, setFormularArten] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/api/formular-arten')
      .then(response => {
        setFormularArten(response.data);
      })
      .catch(error => {
        console.error('Fehler beim Laden der Formulararten:', error);
      });
  }, []);

  const handleFormularAuswahl = (url) => {
    navigate(url);
  };

  return (
    <div className="container mt-5">
      {/* Bootstrap Card Element */}
      <div className="card" style={{ maxWidth: '1000px' }}>
        <div className="card-header text-center">
          <h2>Wählen Sie die Formularart</h2>
        </div>

        <div className="card-body">
          <div className="row">
            {formularArten.map((formular) => (
              <div key={formular.formular_id} className="col-md-4 mb-4">
                <div className="card shadow-sm" onClick={() => handleFormularAuswahl(formular.url)} style={{ cursor: 'pointer' }}>
                  <img src={`/images/${formular.name.toLowerCase()}_preview.png`} className="card-img-top" alt={`${formular.name} Preview`} />
                  <div className="card-body">
                    <h5 className="card-title">{formular.name}</h5>
                    <p className="card-text">{formular.beschreibung}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card-footer text-center">
          {/* Zurück-Button */}
          <button className="btn btn-secondary" onClick={() => navigate(-1)}>Zurück</button>
        </div>
      </div>
    </div>
  );
}

export default FormularAuswahl;
