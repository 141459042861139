import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, ListGroup, Button, Modal, Badge } from 'react-bootstrap'; // Bootstrap-Komponenten

function FormularDetailFachaufsicht() {
  const { id } = useParams(); // Formular-ID aus der URL holen
  const [formular, setFormular] = useState(null);
  const [error, setError] = useState('');
  const [modalShow, setModalShow] = useState(false); // Modal-Steuerung
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Formulardaten abrufen
    axios.get(`${process.env.REACT_APP_API_URL}/api/forms/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      setFormular(response.data); // Formulardaten setzen
    })
    .catch(error => {
      console.error('Fehler beim Laden des Formulars:', error);
      setError('Fehler beim Laden des Formulars');
    });
  }, [id]);

  const handleEndabgabe = () => {
    const token = localStorage.getItem('token');

    axios.post(`${process.env.REACT_APP_API_URL}/api/forms/${id}/endabgabe`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(() => {
      setSuccessMessage('Formular erfolgreich endabgegeben!');
      setTimeout(() => navigate('/formular-container-fachaufsicht'), 2000); // Nach 2 Sekunden zurück zur Übersicht
    })
    .catch(error => {
      console.error('Fehler bei der Endabgabe:', error);
      setError('Fehler bei der Endabgabe des Formulars');
    });
  };

  const handleClose = () => setModalShow(false); // Modal schließen
  const handleShow = () => setModalShow(true);  // Modal öffnen

  const formatDate = (date) => {
    return date ? new Date(date).toLocaleDateString() + ' um ' + new Date(date).toLocaleTimeString() : 'N/A';
  };

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!formular) {
    return <div>Laden...</div>; // Ladezustand anzeigen
  }

  return (
    <div className="container mt-5">
      <Card className="mx-auto" style={{ maxWidth: '800px' }}>
        <Card.Header className="text-center">
          <h2>Formulardetails - Fachaufsicht</h2>
          <div className="text-center mt-2">
            Erstellt am: <strong>{formular.erstellt_am ? formatDate(formular.erstellt_am) : 'N/A'}</strong> -  
            Ersteller: <strong>{formular.erstellt_von ? formular.erstellt_von : 'N/A'}</strong> | 
            Abteilung: <Badge bg="info">{formular.abteilung ? formular.abteilung : 'N/A'}</Badge> - Status: <Badge bg={formular.status === 'Erstellt' ? 'primary' : formular.status === 'Zur Prüfung' ? 'warning' : 'success'}>{formular.status}</Badge>
          </div>
        </Card.Header>

        <Card.Body className="text-center">
          <ListGroup variant="flush" className="text-left">
            <ListGroup.Item><strong>Fahrer Name:</strong> {formular.fahrer_name}</ListGroup.Item>
            <ListGroup.Item><strong>Fahrer Adresse:</strong> {formular.fahrer_adresse}</ListGroup.Item>
            <ListGroup.Item><strong>Kennzeichen:</strong> {formular.kennzeichen}</ListGroup.Item>
            <ListGroup.Item><strong>Verstoß Art:</strong> {formular.verstoß_art}</ListGroup.Item>
            <ListGroup.Item><strong>Verstoß Datum:</strong> {formular.verstoß_datum}</ListGroup.Item>
            <ListGroup.Item><strong>Verstoß Ort:</strong> {formular.verstoß_ort}</ListGroup.Item>
            <ListGroup.Item><strong>Weitere Details:</strong> {formular.weitere_details}</ListGroup.Item>
            <ListGroup.Item>
              <strong>Unterschrift:</strong>
              {formular.unterschrift ? (
                <div className="mt-2">
                  <img src={formular.unterschrift} alt="Unterschrift" style={{ width: '200px', border: '1px solid #ccc' }} />
                </div>
              ) : 'Unterschrift verweigert'}
            </ListGroup.Item>
          </ListGroup>

          {formular.status === 'Erstellt' && (
            <>
              <Button variant="success" onClick={handleShow} className="mt-4">
                Formular direkt endabgeben
              </Button>

              <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Formular endabgeben</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Sind Sie sicher, dass das Formular korrekt ist und endabgegeben werden soll?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                  </Button>
                  <Button variant="primary" onClick={() => { handleEndabgabe(); handleClose(); }}>
                    Ja, Formular endabgeben
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}

          {formular.status === 'Zur Prüfung' && (
            <>
              <Button variant="success" onClick={handleShow} className="mt-4">
                Formular endabgeben
              </Button>

              <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Formular endabgeben</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Sind Sie sicher, dass das Formular endabgegeben werden soll?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                  </Button>
                  <Button variant="primary" onClick={() => { handleEndabgabe(); handleClose(); }}>
                    Ja, Formular endabgeben
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}

          {formular.status === 'Endabgegeben' && (
            <div className="alert alert-success mt-4">
              Formular wurde am <strong>{formatDate(formular.endabgabe_datum)}</strong> durch <strong>{formular.endabgegeben_von}</strong> an die zuständige Stelle endabgegeben.
            </div>
          )}
        </Card.Body>

        <Card.Footer className="text-center">
          <Button variant="secondary" onClick={() => navigate('/formular-container-fachaufsicht')}>
            Zurück zur Übersicht
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default FormularDetailFachaufsicht;
