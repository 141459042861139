import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faFileUpload, faTrash, faComment } from '@fortawesome/free-solid-svg-icons';

const BeweismittelModal = ({ show, handleClose, handleSaveBeweismittel }) => {
  const [files, setFiles] = useState([]);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [comment, setComment] = useState('');

  const handleCapturePhoto = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setCapturedPhoto({
            src: reader.result,
            file: file
          });
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const handleUploadFiles = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*,.pdf';
    input.multiple = true;
    input.onchange = (e) => {
      const uploadedFiles = Array.from(e.target.files);
      setFiles([...files, ...uploadedFiles]);
    };
    input.click();
  };

  const handleAddCapturedPhoto = () => {
    if (capturedPhoto) {
      setFiles([...files, capturedPhoto.file]);
      setCapturedPhoto(null); // Reset after adding
    }
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    handleSaveBeweismittel(files, comment);
    setFiles([]);
    setComment('');
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Beweismittel hinzufügen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Button onClick={handleCapturePhoto}>
              <FontAwesomeIcon icon={faCamera} /> Foto machen
            </Button>
          </Col>
          <Col md={6}>
            <Button onClick={handleUploadFiles}>
              <FontAwesomeIcon icon={faFileUpload} /> Dateien hochladen
            </Button>
          </Col>
        </Row>

        {capturedPhoto && (
          <Card className="mt-3">
            <Card.Img variant="top" src={capturedPhoto.src} />
            <Card.Body>
              <Button onClick={handleAddCapturedPhoto}>Foto hinzufügen</Button>
              <Button variant="secondary" onClick={() => setCapturedPhoto(null)}>
                Verwerfen
              </Button>
            </Card.Body>
          </Card>
        )}

        <Form.Group className="mt-3">
          <Form.Label>Kommentar</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Füge optional einen Kommentar hinzu"
          />
        </Form.Group>

        {files.length > 0 && (
          <div className="mt-3">
            <h5>Hochgeladene Dateien:</h5>
            <ul>
              {files.map((file, index) => (
                <li key={index}>
                  {file.name}
                  <Button variant="danger" size="sm" onClick={() => handleRemoveFile(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Beweismittel speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BeweismittelModal;
