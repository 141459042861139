// OfflinePage.js

import React from 'react';

function OfflinePage() {
  return (
    <div className="container mt-5">
      <h1>Offline</h1>
      <p>Es scheint, als hättest du gerade keine Internetverbindung. Sobald du wieder online bist, werden deine Daten synchronisiert.</p>
    </div>
  );
}

export default OfflinePage;
