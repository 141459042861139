import React, { useState } from 'react';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';

// VK-Auswahl Modal-Komponente
function VKSelectionModal({ show, handleClose, handleSave }) {
  const [selectedVKs, setSelectedVKs] = useState({ bereich1: '', bereich2: '', bereich3: '' });
  const [activeTab, setActiveTab] = useState('bereich1'); // Verwaltet den aktiven Tab

  const bereich1Werte = [
    { name: 'Führer', key: '1' },
    { name: 'Halter', key: '2' },
    { name: 'Führer und Halter', key: '3' },
    { name: 'Verantwortlicher des Fahrzeugs', key: '992' },
    { name: 'Verantwortlicher', key: '993' },
    { name: 'Fußgänger', key: '994' },
    { name: 'Radfahrer', key: '995' },
    { name: 'Reiter', key: '996' },
    { name: 'Beifahrer', key: '997' },
    { name: 'sonstiger Verkehrsteilnehmer', key: '9_1' } // _1 als zusätzliche eindeutige identifikation, der unterstrich und alles dahinter wird nicht ausgegeben oder gespeichert.
  ];

  const bereich2Werte = [
    { name: 'Kraftrad', key: '01' },
    { name: 'Leichtkraftrad', key: '02' },
    { name: 'Kleinkraftrad (Moped, Mokick, Mofa)', key: '03' },
    { name: 'PKW', key: '04' },
    { name: 'PKW mit Anhänger', key: '05' },
    { name: 'LKW', key: '06' },
    { name: 'LKW mit Anhänger', key: '07' },
    { name: 'Kraftomnibus', key: '08' },
    { name: 'KOM mit Anhänger', key: '09' },
    { name: 'KOM mit Fahrgästen', key: '48' },
    { name: 'KOM mit Fahrgästen und Anhänger', key: '49' },
    { name: 'Sattelzugmaschine', key: '10' },
    { name: 'Sattelzugmaschine mit Anhänger', key: '11' },
    { name: 'LuF Zugmaschine', key: '12' },
    { name: 'LuF Zugmaschine mit Anhänger', key: '13' },
    { name: 'selbstfahrende Arbeitsmaschine', key: '14' },
    { name: 'Anhänger', key: '15' },
    { name: 'Zugmaschine', key: '20' },
    { name: 'Anhänger zugmaschine', key: '21' },
    { name: 'Anhänger LuF Zugmaschine', key: '22' },
    { name: 'Wohnanhänger', key: '23' },
    { name: 'Kleintransporter (< 3,5t zGG)', key: '30' },
    { name: 'Kleintransporter (< 3,5t zGG) mit Anhänger', key: '31' },
    { name: 'Zugmaschine', key: '40' },
    { name: 'Wohnmobil', key: '41' },
    { name: 'Gespannfuhrwerk', key: '42' },
    { name: 'Straßenbahn', key: '43' },
    { name: 'sonstiges Fahrzeug ***', key: '99_1' },
    { name: 'sonstiger Verkehrsteilnehmer ***', key: '99_2' }
    
  ];

  const bereich3Werte = [
    { name: 'KFZ bis 7,5t', key: 'A' },
    { name: 'KFZ über 7,5t', key: 'B' },
    { name: 'KFZ bis 7,5t mit Gefahrgut', key: 'C' },
    { name: 'KFZ über 7,5t mit Gefahrgut', key: 'D' },
    { name: 'Anhänger bis 2t', key: 'E' },
    { name: 'Anhänger über 2t', key: 'F' },
    { name: 'Anhänger bis 2t mit Gefahrgut', key: 'G' },
    { name: 'Anhänger über 2t mit Gefahrgut', key: 'H' },
    { name: 'Gefahrguttransport', key: 'I' }
  ];

  // Mapping von Bereich 1 auf zulässige Optionen in Bereich 2 und 3
  const mapping = {
    '1': { allowedBereich2: '*', allowedBereich3: '*' },
    '2': { allowedBereich2: '*', allowedBereich3: '*' },
    '3': { allowedBereich2: '*', allowedBereich3: '*' },
  //'3': { allowedBereich2: ['06', '07'], allowedBereich3: ['E', 'F'] },
    '9_7': { allowedBereich2: '*', allowedBereich3: '*' }, // Beispiel für Schlüssel '9'
};

// Funktion zum Filtern von Bereich 2
  const getFilteredBereich2 = () => {
    if (!selectedVKs.bereich1) return bereich2Werte;

    const allowedKeys = mapping[selectedVKs.bereich1]?.allowedBereich2 || [];

    if (allowedKeys === '*') {
      return bereich2Werte;
    }

    return bereich2Werte.filter((item) => allowedKeys.includes(item.key.split('_')[0])); // Filtere basierend auf dem Teil vor dem Unterstrich
  };

  // Funktion zum Filtern von Bereich 3
  const getFilteredBereich3 = () => {
    if (!selectedVKs.bereich1) return bereich3Werte;

    const allowedKeys = mapping[selectedVKs.bereich1]?.allowedBereich3 || [];

    if (allowedKeys === '*') {
      return bereich3Werte;
    }

    return bereich3Werte.filter((item) => allowedKeys.includes(item.key.split('_')[0])); // Filtere basierend auf dem Teil vor dem Unterstrich
  };

  // Funktion zum Verarbeiten der VK-Auswahl
  const handleVKClick = (bereich, value) => {
    setSelectedVKs((prevSelected) => {
      const newValue = prevSelected[bereich] === value ? '' : value; // Verwende den vollständigen Schlüssel

      // Nur zum nächsten Tab wechseln, wenn der Button neu ausgewählt wird, nicht beim Abwählen
      if (newValue !== '') {
        if (bereich === 'bereich1') {
          setActiveTab('bereich2');
        } else if (bereich === 'bereich2') {
          setActiveTab('bereich3');
        }
      }

      return { 
        ...prevSelected, 
        [bereich]: newValue // Speichere den vollständigen Schlüssel
      };
    });
  };

  // Funktion zum Schließen des Modals
  const handleCloseModal = () => {
    setActiveTab('bereich1'); // Zurück zum ersten Tab
    handleClose(); // Schließe das Modal
  };

  // Diese Funktion stellt sicher, dass nur der Teil vor dem Unterstrich gespeichert wird
  const handleSaveClick = () => {
    const finalVK = `${selectedVKs.bereich1.split('_')[0]}${selectedVKs.bereich2.split('_')[0]}${selectedVKs.bereich3.split('_')[0]}`; // Extrahiere den Teil vor dem Unterstrich für alle Bereiche
    handleSave(finalVK); // VK an die Elternkomponente übergeben
    handleClose(); // Schließt das Modal
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>VK Person/Fahrzeug/Zusatz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="vk-selection-tabs" className="mb-3">
          <Tab eventKey="bereich1" title="Person">
            <div className="d-flex flex-wrap">
              {bereich1Werte.map((item) => (
                <Button
                  key={item.key}
                  variant={selectedVKs.bereich1 === item.key ? "primary" : "outline-primary"}
                  onClick={() => handleVKClick('bereich1', item.key)} // Verwende den vollständigen Schlüssel wie '9_7'
                  className={`m-2 ${selectedVKs.bereich1 === item.key ? 'active' : ''}`} // Aktiviere nur den vollständigen Schlüssel
                >
                  {item.name} ({item.key.split('_')[0]}) {/* Zeige nur den Teil vor dem Unterstrich an */}
                </Button>
              ))}
            </div>
          </Tab>
          <Tab eventKey="bereich2" title="Fahrzeug">
            <div className="d-flex flex-wrap">
              {getFilteredBereich2().map((item) => (
                <Button
                  key={item.key}
                  variant={selectedVKs.bereich2 === item.key ? "primary" : "outline-primary"}
                  onClick={() => handleVKClick('bereich2', item.key)} // Verwende den vollständigen Schlüssel
                  className={`m-2 ${selectedVKs.bereich2 === item.key ? 'active' : ''}`} // Aktiviere nur den vollständigen Schlüssel
                >
                  {item.name} ({item.key.split('_')[0]}) {/* Zeige nur den Teil vor dem Unterstrich an */}
                </Button>
              ))}
            </div>
          </Tab>
          <Tab eventKey="bereich3" title="Zusatz">
            <div className="d-flex flex-wrap">
              {getFilteredBereich3().map((item) => (
                <Button
                  key={item.key}
                  variant={selectedVKs.bereich3 === item.key ? "primary" : "outline-primary"}
                  onClick={() => handleVKClick('bereich3', item.key)} // Verwende den vollständigen Schlüssel
                  className={`m-2 ${selectedVKs.bereich3 === item.key ? 'active' : ''}`} // Aktiviere nur den vollständigen Schlüssel
                >
                  {item.name} ({item.key.split('_')[0]}) {/* Zeige nur den Teil vor dem Unterstrich an */}
                </Button>
              ))}
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 text-left">
          <small className="text-muted">
    * nur für Steuerung der Ermittlung von TB-Nr., ohne Auswirkung auf Ausdruck der Bescheide. <br />
    ** A-H nur für Gewichtsverstöße, I nur für Geschwindigkeitsüberschreitungen. <br />
    *** Angabe der Verkehrsbeteiligung im Langtext erforderlich.
  </small>
        </div>
        <Button variant="secondary" onClick={handleClose}>Abbrechen</Button>
        <Button variant="primary" onClick={handleSaveClick}>Übernehmen</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VKSelectionModal;