import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios'; // Falls nicht bereits importiert

import 'bootstrap/dist/css/bootstrap.min.css';
import { getAllFormulare, deleteFormular } from './offlineStorage'; // Passe den Pfad entsprechend an
import Logout from './components/Auth/Logout';
import Login from './components/Auth/Login';
import Home from './components/Home';
import FormularAuswahl from './components/Formular/FormularAuswahl';
import FormularDEBErstellen from './components/Formular/FormularDEBErstellen';
import FormularVEVErstellen from './components/Formular/FormularVEVErstellen'; // Diese Datei erstellst du später
import FormularLASERErstellen from './components/Formular/FormularLASERErstellen'; // Diese Datei erstellst du später
import FormularContainerKollegen from './components/Formular/FormularContainerKollegen';  // Import für Kollegen-Container
import FormularContainerFachaufsicht from './components/Formular/FormularContainerFachaufsicht';  // Import für Fachaufsicht-Container
import FormularDetailKollegen from './components/Formular/FormularDetailKollegen';  // Import für Kollegen-Detailseite
import FormularDetailFachaufsicht from './components/Formular/FormularDetailFachaufsicht';  // Import für Fachaufsicht-Detailseite
import AdminPanel from './components/Admin/AdminPanel';
import UserSettings from './components/User/UserSettings';
import OfflinePage from './offlinePage'; // Falls in einem anderen Ordner, passe den Pfad an.
import WebSocketNotifications from './components/Utils/WebSocketNotifications';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);  // Speichert den Nutzerstatus
  const [isLoading, setIsLoading] = useState(true);  // Zum Laden der Benutzerdaten
  const [isSyncing, setIsSyncing] = useState(false);  // Neuer Zustand für die Synchronisierung
  const [offlineFormulare, setOfflineFormulare] = useState([]);  // Formulare im Offline-Modus

  // Überprüfen, ob der Benutzer eingeloggt ist und Formulare synchronisieren
  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      const storedUser = localStorage.getItem('user');
      
      console.log(`Token: ${token}`);  // Debug-Ausgabe des Tokens
      console.log(`User: ${storedUser}`);  // Debug-Ausgabe der Benutzerdaten

      // Benutzer als eingeloggt setzen, wenn ein Token existiert
      setIsLoggedIn(!!token && !!storedUser); // Stelle sicher, dass Token und Benutzerinformationen existieren
      if (storedUser) {
        setUser(JSON.parse(storedUser));  // Nutzerinfos laden, wenn sie vorhanden sind
      }
      setIsLoading(false);  // Das Laden der Benutzerdaten ist abgeschlossen
    };

    const syncFormulare = async () => {
      const offlineFormulare = await getAllFormulare(); // Formulare aus IndexedDB holen

      console.log('Offline Formulare:', offlineFormulare);
      
      if (offlineFormulare.length > 0 && navigator.onLine) {
        setIsSyncing(true);
        offlineFormulare.forEach((formular) => {
          const token = localStorage.getItem('token');
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/forms`,
              formular,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then(() => {
              deleteFormular(formular.id); // Nach erfolgreicher Synchronisierung löschen
              console.log('Formular erfolgreich synchronisiert:', formular);
            })
            .catch((error) =>
              console.error('Fehler bei der Synchronisation:', error)
            );
        });
        setIsSyncing(false);  // Synchronisation abgeschlossen
      }
    };

    // Überprüfe Login-Status und synchronisiere Formulare beim Start der App
    checkLoginStatus();
    syncFormulare();

    // Event Listener für Online-Status
    window.addEventListener('online', syncFormulare);
    window.addEventListener('storage', checkLoginStatus);

    return () => {
      window.removeEventListener('online', syncFormulare);
      window.removeEventListener('storage', checkLoginStatus);
    };
  }, []);

  // Wenn die Benutzerdaten noch geladen werden, zeigen wir einen Ladehinweis
  if (isLoading) {
    return <div>Laden...</div>;  // Oder ein besser gestalteter Ladezustand
  }

  return (
    <Router>
      <div>
        {isSyncing && (
          <div className="alert alert-info text-center">
            Synchronisiere Formulare...
          </div>
        )}
        {offlineFormulare.length > 0 && !navigator.onLine && (
          <div className="alert alert-warning text-center">
            Sie sind offline. {offlineFormulare.length} Formulare warten auf die Synchronisierung.
          </div>
        )}
        {offlineFormulare.length > 0 && !navigator.onLine && (
          <div className="badge bg-warning text-center">
            {offlineFormulare.length} nicht synchronisierte Formulare
          </div>
        )}
        
        
        <Routes>
          {/* Wenn eingeloggt, zur Home-Seite weiterleiten, sonst zur Login-Seite */}
          <Route path="/" element={isLoggedIn ? <Navigate to="/home" /> : <Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/formular-auswahl" element={<FormularAuswahl />} />
          <Route path="/formular-deb-erstellen" element={<FormularDEBErstellen />} />
          <Route path="/formular-vev-erstellen" element={<FormularVEVErstellen />} />
          <Route path="/formular-laser-erstellen" element={<FormularLASERErstellen />} />
          <Route path="/user-settings" element={<UserSettings />} />
          <Route path="/admin-panel" element={<AdminPanel />} />

          <Route path="/logout" element={<Logout />} />
        
          {/* Route für die Fachaufsicht */}
          <Route path="/formular-container-fachaufsicht" element={<FormularContainerFachaufsicht />} />
          <Route path="/formular-detail-fachaufsicht/:id" element={<FormularDetailFachaufsicht />} />

          {/* Route für die Kollegen */}
          <Route path="/formular-container-kollegen" element={<FormularContainerKollegen />} />
          <Route path="/formular-detail-kollegen/:id" element={<FormularDetailKollegen />} />
        
          <Route path="*" element={<Navigate to={isLoggedIn ? "/home" : "/login"} />} /> {/* Fallback für ungültige Routen */}
          <Route path="/offline" element={<OfflinePage />} />
        </Routes>
        <WebSocketNotifications />
      </div>
    </Router>
  );
}

export default App;
