import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const socket = io(process.env.REACT_APP_API_URL);

const WebSocketNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    socket.on('benachrichtigung', (data) => {
    console.log('Benachrichtigung erhalten:', data); // Debugging
      toast.info(
        <div>
          <p>{data.message}</p>
          <a href={data.link}>Zum Formular</a>
        </div>
      );

      // Füge Benachrichtigungen zu einem Array hinzu
      setNotifications((prev) => [...prev, data]);
    });

    return () => {
      socket.off('benachrichtigung');
    };
  }, []);

  return <ToastContainer />;
};

export default WebSocketNotifications;
