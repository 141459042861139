import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // Token entfernen
    localStorage.removeItem('token');
    // Zur Login-Seite umleiten
    navigate('/');
    window.location.reload();  // Seite neuladen, um sicherzustellen, dass der Zustand aktualisiert wird
  }, [navigate]);

  return null;
}

export default Logout;
