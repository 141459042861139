import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'; 
import { Card, Form, Button, Alert, Row, Col, Modal, Accordion, ListGroup, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { addFormular } from '../../offlineStorage'; // Passe den Pfad entsprechend an

function FormularErstellen() {
  const [formData, setFormData] = useState({
    fahrer_name: '',
    fahrer_adresse: '',
    kennzeichen: '',
    verstoß_art: '',
    verstoß_datum: '',
    verstoß_ort: '',
    weitere_details: '',
    unterschrift_verweigert: false
  });

  const sigCanvas = useRef({});
  const [successMessage, setSuccessMessage] = useState('');
  const [modalShow, setModalShow] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [validated, setValidated] = useState(false); 
  const [showTooltip, setShowTooltip] = useState(false); 
  const [vorlagenShow, setVorlagenShow] = useState(false); 
  const navigate = useNavigate();

  const vorlagen = ['Geschwindigkeitsüberschreitung', 'Rotlichtverstoß', 'Handy am Steuer', 'Alkohol am Steuer', 'Gurt nicht angelegt'];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, unterschrift_verweigert: e.target.checked });
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const handleSubmit = (e) => {
  e.preventDefault();

  const form = e.currentTarget;
  if (form.checkValidity() === false) {
    e.stopPropagation();
    setValidated(true);
    return;
  }

  setLoading(true);

  // Prüfe, ob der Benutzer online ist
  if (!navigator.onLine) {
    // Unterschrift verarbeiten
    const signatureData = formData.unterschrift_verweigert
      ? null
      : sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    // Formular-Daten zusammenstellen
    const offlineFormular = {
      ...formData,
      unterschrift: signatureData,
      timestamp: new Date().toISOString(),
    };

    // Formular in IndexedDB speichern
    addFormular(offlineFormular)
      .then(() => {
        setSuccessMessage('Formular im Offline-Modus gespeichert!');
        console.log('Formular erfolgreich offline gespeichert:', offlineFormular); // DEBUGGING: Konsole überprüfen
        setLoading(false);
        
        // Nach erfolgreicher Speicherung
setTimeout(() => {
  const storedUser = localStorage.getItem('user');
  const user = storedUser ? JSON.parse(storedUser) : null;

  if (user) {
    // Leite zum entsprechenden Container weiter, wenn die Rolle bekannt ist
    if (user.role === 'Fachaufsicht') {
      navigate('/formular-container-fachaufsicht');
    } else {
      navigate('/formular-container-kollegen');
    }
  } else {
    // Leite zur Home-Seite weiter, falls keine Rolle bekannt ist (z.B. offline)
    navigate('/home');
  }
}, 1500);
      })
      .catch((error) => {
        console.error('Fehler beim Speichern im Offline-Modus:', error);
        setLoading(false);
      });
  } else {
    // Wenn online, Formular an den Server senden
    const token = localStorage.getItem('token');
    const signatureData = formData.unterschrift_verweigert
      ? null
      : sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');

    axios.post(
  `${process.env.REACT_APP_API_URL}/api/forms`,
  { ...formData, unterschrift: signatureData },
  {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }
)
.then((response) => {
  console.log('Formular erfolgreich erstellt, Antwort:', response.data);
  setSuccessMessage('Formular erfolgreich erstellt! Weiterleitung...');
  setLoading(false);  // Ladezustand nach erfolgreicher Formularerstellung beenden

  // Benutzerrollen prüfen und zur entsprechenden Übersichtsseite weiterleiten
  const user = JSON.parse(localStorage.getItem('user'));
  
  setTimeout(() => {
    if (user.role === 'Fachaufsicht') {
      navigate('/formular-container-fachaufsicht');  // Für Fachaufsicht
    } else {
      navigate('/formular-container-kollegen');  // Für Kollegen
    }
  }, 1500);
})
.catch((error) => {
  console.error('Fehler beim Erstellen des Formulars:', error);
  setLoading(false);  // Ladezustand bei Fehler beenden
});

  }
};

  const handleClose = () => setModalShow(false); 
  const handleShow = () => setModalShow(true);  

  const handleVorlagenShow = () => setVorlagenShow(true);
  const handleVorlagenClose = () => setVorlagenShow(false);
  const handleVorlageAuswahl = (vorlage) => {
    setFormData({ ...formData, verstoß_art: vorlage });
    handleVorlagenClose();
  };

  return (
    <div className="container mt-5">
      <Card className="mx-auto" style={{ maxWidth: '1000px' }}>
        <Card.Header>
          <h2 className="text-center">Neues Formular erstellen</h2>
        </Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            <Col md={6}>
              {successMessage && (
                <Alert variant="success" className="text-center">
                  {successMessage}
                </Alert>
              )}

              <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Tipps zum Ausfüllen des Formulars</Accordion.Header>
          <Accordion.Body>
            Hier sind einige Tipps, wie du das Formular korrekt ausfüllst:
            <ul>
              <li><strong>Fahrer Name:</strong> Trage den vollständigen Namen des Fahrers ein.</li>
              <li><strong>Kennzeichen:</strong> Achte darauf, dass das Kennzeichen korrekt ist.</li>
              <li><strong>Verstoß Art:</strong> Wähle die richtige Verstoß Art aus den Vorlagen aus oder gib sie manuell ein.</li>
              <li><strong>Unterschrift:</strong> Stelle sicher, dass die Unterschrift korrekt erfasst ist oder wähle "Unterschrift verweigert".</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

              <Form noValidate validated={validated}>
                <Form.Group className="mb-3">
                  <Form.Label>Fahrer Name</Form.Label>
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-fahrer-name">Gib den vollständigen Namen des Fahrers ein.</Tooltip>}
                  >
                    <Form.Control 
                      type="text" 
                      id="fahrer_name" 
                      name="fahrer_name" 
                      value={formData.fahrer_name} 
                      onChange={handleChange} 
                      required 
                    />
                  </OverlayTrigger>
                  <Form.Control.Feedback type="invalid">Bitte gib den Fahrernamen an.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Fahrer Adresse</Form.Label>
                  <Form.Control 
                    type="text" 
                    id="fahrer_adresse" 
                    name="fahrer_adresse" 
                    value={formData.fahrer_adresse} 
                    onChange={handleChange} 
                    required 
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib die Fahreradresse an.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Kennzeichen</Form.Label>
                  <Form.Control 
                    type="text" 
                    id="kennzeichen" 
                    name="kennzeichen" 
                    value={formData.kennzeichen} 
                    onChange={handleChange} 
                    required 
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib das Kennzeichen an.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Verstoß Art</Form.Label>
                  <div className="d-flex">
                    <Form.Control 
                      type="text" 
                      id="verstoß_art" 
                      name="verstoß_art" 
                      value={formData.verstoß_art} 
                      onChange={handleChange} 
                      required 
                    />
                    <Button variant="info" className="ms-2" onClick={handleVorlagenShow}>
                      Vorlagen anzeigen
                    </Button>
                  </div>
                  <Form.Control.Feedback type="invalid">Bitte wähle oder gib die Verstoß Art an.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Verstoß Datum</Form.Label>
                  <Form.Control 
                    type="date" 
                    id="verstoß_datum" 
                    name="verstoß_datum" 
                    value={formData.verstoß_datum} 
                    onChange={handleChange} 
                    required 
                  />
                  <Form.Control.Feedback type="invalid">Bitte wähle ein Datum.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Verstoß Ort</Form.Label>
                  <Form.Control 
                    type="text" 
                    id="verstoß_ort" 
                    name="verstoß_ort" 
                    value={formData.verstoß_ort} 
                    onChange={handleChange} 
                    required 
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib den Ort des Verstoßes an.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Weitere Details</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    id="weitere_details" 
                    name="weitere_details" 
                    value={formData.weitere_details} 
                    onChange={handleChange} 
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unterschrift</Form.Label>
                  <div className="signature-container" style={{ border: '1px solid #ccc', width: '100%', height: '150px', maxWidth: '500px', margin: '0 auto' }}>
                    <SignatureCanvas 
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{ className: 'signature-canvas', width: 500, height: 150 }}
                    />
                  </div>
                  <Button variant="secondary" className="mt-2" onClick={clearSignature}>
                    Unterschrift löschen
                  </Button>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    id="unterschrift_verweigert" 
                    name="unterschrift_verweigert" 
                    label="Unterschrift verweigert" 
                    checked={formData.unterschrift_verweigert}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="text-center">
          <Button variant="primary" className="me-2" onClick={handleShow}>
            Formular abgeben
          </Button>
          <Button variant="secondary" onClick={() => navigate('/home')}>
            Abbrechen
          </Button>
        </Card.Footer>
      </Card>

      {/* Modal für Bestätigung */}
      <Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Formular abgeben</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bist du sicher, dass das Formular korrekt ausgefüllt wurde und nun abgegeben werden soll?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={(e) => { handleSubmit(e); handleClose(); }}>
            Ja, Formular abgeben
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal für Vorlagen */}
      <Modal show={vorlagenShow} onHide={handleVorlagenClose}>
        <Modal.Header closeButton>
          <Modal.Title>Vorlagen für Verstoß Art</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {vorlagen.map((vorlage, index) => (
              <ListGroup.Item key={index} action onClick={() => handleVorlageAuswahl(vorlage)}>
                {vorlage}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleVorlagenClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FormularErstellen;
