import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'; 
import { Card, Form, Button, Alert, Row, Col, InputGroup, Modal, Accordion, ProgressBar, Tabs, Tab, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'; // Icon für den Button Familienname Geburtsname
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addFormular } from '../../offlineStorage';
import { QRCodeCanvas } from 'qrcode.react';
import jsPDF from 'jspdf'; // Für die PDF-Generierung
import VKSelectionModal from '../Modals/VKSelectionModal'; // Import des neuen Modals
import BeweismittelSelectionModal from '../Modals/BeweismittelSelectionModal'; // Import des neuen Modals
import TatbestandsnummerModal from '../Modals/TatbestandsnummerModal'; // Import des neuen Modals
import { useGPSLocation } from '../Utils/useGPSLocation';
import { getLoggedInUsername } from '../Utils/authUtils';
import AusweisModal from '../Modals/AusweisModal'; // Importiere die ausgelagerte Komponente
import BeweismittelModal from '../Modals/BeweismittelModal'; // Importiere das neue Modal


function FormularDEBErstellen() {
  const { locationData, loading: gpsLoading, error, fetchLocation } = useGPSLocation();
  const [formData, setFormData] = useState({
    // Vorhandene Felder
    tatort_strasse: '',
    tatort_strasse_nr: '',
    tatort_plz: '',
    tatort_ort: '',
    tatort_zusatz: '',
    tattag: '',
    tatzeit: '',
    kennzeichen: '',
    tbnr_tabnr: '',
    konkretisierung: '',
    erl_wert: '',
    vorw_wert: '',
    behinderung: false,
    gefährdung: false,
    ha: '',
    vk: '',
    beweismittel: '',
    hersteller: '',
    euro: '',
    vorname: '',
    familienname: '',
    geburtsname: '',
    geschlecht: 'M',
    geburtsdatum: '',
    geburtsort: '',
    strasse_hausnummer: '',
    postleitzahl: '',
    wohnort: '',
    name_zeuge: '',
    anschrift_zeuge: '',
    anhörung: '',
    anhörung1: true,
    ergänzungen_tatvorwurf: '',
    bemerkungen: '',
    belehrung1: true,
    belehrung2: false,
    belehrung3: false,
    belehrung4: false,
    verwarnung_abgelehnt: false,
    unterschrift_beamter: null,
    unterschrift_betroffener: null,
    unterschrift_verweigert: false,
    quittung_art: 'keine_quittung', // Neue Option für die Quittung
    email: '', // E-Mail, falls ausgewählt
  });
  
  const loggedInUsername = getLoggedInUsername(); // Benutzername abrufen
  
  const handleLocationButtonClick = () => {
    fetchLocation();
  };
  
  useEffect(() => {
    const currentDate = new Date();
    setFormData((prevFormData) => ({
      ...prevFormData,
      tattag: currentDate.toISOString().split('T')[0],
      tatzeit: currentDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }),
    }));
  }, []);
  
  useEffect(() => {
    if (locationData) {
      const { address } = locationData;
      setFormData((prevFormData) => ({
        ...prevFormData,
      tatort_plz: address.postcode || '',
      tatort_ort: address.city || address.town || address.village || '',
      tatort_strasse: address.road || '',
      }));
    }
  }, [locationData]);
  
  const autohersteller = [
    'Volkswagen', 'Mercedes-Benz', 'BMW', 'Audi', 'Ford', 'Opel', 'Skoda', 
    'Renault', 'Peugeot', 'Toyota', 'Hyundai', 'Fiat', 'Kia', 'Mazda', 
    'Nissan', 'Seat', 'Dacia', 'Volvo', 'Citroën', 'Mitsubishi', 'Honda', 
    'Mini', 'Suzuki', 'Tesla', 'Alfa Romeo', 'Jeep', 'Land Rover', 'Porsche', 
    'Lexus', 'Jaguar', 'Subaru', 'Chevrolet', 'Chrysler', 'Dodge', 'Ferrari', 
    'Lamborghini', 'Maserati', 'Bentley', 'Rolls-Royce', 'Aston Martin', 
    'Bugatti', 'McLaren', 'Abarth', 'Acura', 'Aston Martin', 'Bentley', 
    'Buick', 'Cadillac', 'Cupra', 'Daewoo', 'Daihatsu', 'DS Automobiles', 
    'Genesis', 'GMC', 'Hummer', 'Infiniti', 'Isuzu', 'Koenigsegg', 'Lada', 
    'Lancia', 'Lincoln', 'Lotus', 'MG', 'Morgan', 'Pagani', 'Plymouth', 
    'Polestar', 'Pontiac', 'RAM', 'Saab', 'Smart', 'SsangYong', 'Tata', 'Sonstiges in Bemerkung'
  ];
  
  const haOptions = [
    'Behördenfahrzeuge', 'Versicherungskennzeichen', 'Natofahrzeuge GB privat', 
    'Natofahrzeuge GB Militär', 'Natofahrzeuge USA', 'Natofahrzeuge NL Militär', 
    'Zivilfahrzeuge NL', 'Rotes Kennzeichen', 'Keine automatische Halterermittlung', 'Diplomatenfahrzeuge'
  ];
  
  // States für das Modals
  const [beweismittelModalShow, setBeweismittelModalShow] = useState(false);
  const [tatbestandsnummerModalShow, setTatbestandsnummerModalShow] = useState(false);
  const [selectedTatbestandsnummern, setSelectedTatbestandsnummern] = useState([]);
  const [modalShow, setModalShow] = useState(false); 
  const [showPasswordModal, setShowPasswordModal] = useState(false);  // Modal für das Passwort
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);  // Modal für den QR-Code
  const [showBeweismittelModal, setShowBeweismittelModal] = useState(false);
  const [showAusweisModal, setShowAusweisModal] = useState(false); // Zustand für das Modal
  
  const [currentStep, setCurrentStep] = useState(1);
  const [vkModalShow, setVkModalShow] = useState(false);
  const [password, setPassword] = useState('');  // Passwort für die verschlüsselte PDF
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [validated, setValidated] = useState(false);
  const [beweismittel, setBeweismittel] = useState([]);
  const sigCanvasBetroffener = useRef(null);
  const sigCanvasBeamter = useRef(null);
  
  const navigate = useNavigate();

  const handleOpenAusweisModal = () => setShowAusweisModal(true);  // Öffnet das Modal
  const handleCloseAusweisModal = () => setShowAusweisModal(false);  // Schließt das Modal
  const handleOpenBeweismittelModal = () => setShowBeweismittelModal(true);
  const handleCloseBeweismittelModal = () => setShowBeweismittelModal(false);
  
  const handleSaveBeweismittel = (files, comments) => {
    const newBeweismittel = files.map((file, index) => ({
      file,
      comment: comments[index],
    }));
    setBeweismittel([...beweismittel, ...newBeweismittel]);
  };
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };
  
  const handleVKSave = (selectedVK) => {
    setFormData({ ...formData, vk: selectedVK });
  };
  
  const handleBeweismittelSave = (selectedBeweismittel) => {
    setFormData({ ...formData, beweismittel: selectedBeweismittel });
  };

  const handleTatbestandsnummerSave = (selectedItems) => {
    setSelectedTatbestandsnummern(selectedItems);
    setFormData({ ...formData, tbnr_tabnr: selectedItems.map((item) => item.key).join(', ') });
  };

// Speichern der Unterschrift für Betroffene
const saveSignatureBetroffener = async () => {
    if (sigCanvasBetroffener.current) {
        const trimmedCanvas = sigCanvasBetroffener.current.getTrimmedCanvas();
        const signatureData = trimmedCanvas.toDataURL('image/png');

        // Zuerst lokal zwischenspeichern, damit die Unterschrift bei Schrittwechseln bleibt
        setFormData((prevFormData) => ({
            ...prevFormData,
            unterschrift_betroffener_local: signatureData // Lokale Speicherung für Schrittwechsel
        }));

        // Dann die Unterschrift auf dem Server speichern
        try {
            const response = await axios.post('/api/save-signature', {
                signatureData,
                signatureType: 'betroffener',
                userType: 'betroffener'
            });
            const signaturePath = response.data.signaturePath;

            // Pfad der auf dem Server gespeicherten Unterschrift im Formular speichern
            setFormData((prevFormData) => ({
                ...prevFormData,
                unterschrift_betroffener: signaturePath
            }));
        } catch (error) {
            console.error('Fehler beim Speichern der Unterschrift des Betroffenen:', error);
        }
    }
};

// Speichern der Unterschrift für Beamte (ähnlich wie bei Betroffenen)
const saveSignatureBeamter = async () => {
    if (sigCanvasBeamter.current) {
        const trimmedCanvas = sigCanvasBeamter.current.getTrimmedCanvas();
        const signatureData = trimmedCanvas.toDataURL('image/png');

        // Zuerst lokal zwischenspeichern
        setFormData((prevFormData) => ({
            ...prevFormData,
            unterschrift_beamter_local: signatureData // Lokale Speicherung für Schrittwechsel
        }));

        // Dann auf dem Server speichern
        try {
            const response = await axios.post('/api/save-signature', {
                signatureData,
                signatureType: 'beamter',
                userType: 'beamter'
            });
            const signaturePath = response.data.signaturePath;

            // Pfad der auf dem Server gespeicherten Unterschrift speichern
            setFormData((prevFormData) => ({
                ...prevFormData,
                unterschrift_beamter: signaturePath
            }));
        } catch (error) {
            console.error('Fehler beim Speichern der Unterschrift des Beamten:', error);
        }
    }
};

// Laden der Unterschrift für Betroffene
const loadSignatureBetroffener = async () => {
    if (sigCanvasBetroffener.current) {
        sigCanvasBetroffener.current.clear();

        const canvas = sigCanvasBetroffener.current.getCanvas();
        const context = canvas.getContext('2d');
        const image = new Image();

        if (formData.unterschrift_betroffener_local) {
            // Zuerst die lokal zwischengespeicherte Unterschrift laden
            image.src = formData.unterschrift_betroffener_local;
        } else if (formData.unterschrift_betroffener) {
            // Wenn keine lokale Unterschrift vorhanden ist, vom Server laden
            image.src = formData.unterschrift_betroffener;
        }

        image.onload = () => {
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
    }
};

// Laden der Unterschrift für Beamte (ähnlich wie bei Betroffenen)
const loadSignatureBeamter = async () => {
    if (sigCanvasBeamter.current) {
        sigCanvasBeamter.current.clear();

        const canvas = sigCanvasBeamter.current.getCanvas();
        const context = canvas.getContext('2d');
        const image = new Image();

        if (formData.unterschrift_beamter_local) {
            // Zuerst die lokal zwischengespeicherte Unterschrift laden
            image.src = formData.unterschrift_beamter_local;
        } else if (formData.unterschrift_beamter) {
            // Wenn keine lokale Unterschrift vorhanden ist, vom Server laden
            image.src = formData.unterschrift_beamter;
        }

        image.onload = () => {
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
    }
};

// Unterschrift löschen (Betroffener)
const clearSignatureBetroffener = () => {
    sigCanvasBetroffener.current.clear();
    setFormData((prevFormData) => ({
        ...prevFormData,
        unterschrift_betroffener: null,
        unterschrift_betroffener_local: null
    }));
};

// Unterschrift löschen (Beamter)
const clearSignatureBeamter = () => {
    sigCanvasBeamter.current.clear();
    setFormData((prevFormData) => ({
        ...prevFormData,
        unterschrift_beamter: null,
        unterschrift_beamter_local: null
    }));
};


// Lade die Signaturen bei Schrittwechsel
useEffect(() => {
    if (currentStep === 4) {
        loadSignatureBetroffener();
    }
    if (currentStep === 5) {
        loadSignatureBeamter();
    }
}, [currentStep]);

  const handleNext = () => {
  saveSignatureBetroffener();
  saveSignatureBeamter();
  setCurrentStep(currentStep + 1);
};

const handlePrev = () => {
  saveSignatureBetroffener();
  saveSignatureBeamter();
  setCurrentStep(currentStep - 1);
};

// Funktion zum Abbrechen
  const handleCancel = () => {
    navigate('/home'); // Zurück zur Startseite
  };

  // Funktion zur Generierung eines zufälligen 6-stelligen Passworts
  const generateRandomPassword = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  // QR-Code Generierung
  const generateQRCode = () => {
    const qrData = `
      Verstoß: ${formData.verstoß_art}
      Tatort: ${formData.tatort_strasse}, ${formData.tatort_ort}
      Datum: ${formData.tattag}
      Name: ${formData.vorname} ${formData.familienname}
    `;
    return <QRCodeCanvas value={qrData} size={256} />;
  };

  // PDF-Generierung und Verschlüsselung
  const generatePDF = (formData, password) => {
    const doc = new jsPDF();
    doc.text(`Quittung für ${formData.vorname} ${formData.familienname}`, 10, 10);
    doc.text(`Verstoß: ${formData.verstoß_art}`, 10, 20);
    doc.text(`Tatort: ${formData.tatort_strasse}, ${formData.tatort_ort}`, 10, 30);
    doc.text(`Datum: ${formData.tattag}`, 10, 40);

    // Weitere Formularinformationen
    doc.save('quittung.pdf', { password: password }); // Platzhalter für Verschlüsselung
    return doc.output('blob'); // PDF als Blob zurückgeben
  };

   // Funktion zur Überwachung der Kennzeichen-Eingabe
  const handleKennzeichenChange = (e) => {
    const value = e.target.value.toUpperCase(); // Umwandlung in Großbuchstaben
    const validValue = value.replace(/[^A-Z0-9-]/g, ''); // Nur Buchstaben, Zahlen und Bindestriche zulassen
    setFormData({ ...formData, kennzeichen: validValue });
  };

  const handleNavigation = () => {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;

    if (user.role === 'Fachaufsicht') {
      navigate('/formular-container-fachaufsicht');  // Für Fachaufsicht
    } else {
      navigate('/formular-container-kollegen');  // Für Kollegen
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    // Wenn das HA-Feld leer ist, wird "Kennzeichen nach StVZO" als Standardwert gesetzt
    const haValue = formData.ha === '' ? 'Kennzeichen nach StVZO' : formData.ha;

    const dataToSubmit = {
      ...formData,
      ha: haValue,  // Setze den HA-Wert entsprechend
    };

    setLoading(true);

    if (!navigator.onLine) {
    
      // Unterschriften als Base64-String aus den Canvas-Elementen
      const signatureDataBetroffener = formData.unterschrift_verweigert || !sigCanvasBetroffener.current
      ? null
      : sigCanvasBetroffener.current.getTrimmedCanvas().toDataURL('image/png');

    const signatureDataBeamter = !sigCanvasBeamter.current
      ? null
      : sigCanvasBeamter.current.getTrimmedCanvas().toDataURL('image/png');

      const offlineFormular = {
        ...formData,
        unterschrift_betroffener: signatureDataBetroffener,
        unterschrift_beamter: signatureDataBeamter,
        timestamp: new Date().toISOString(),
      };

      addFormular(offlineFormular)
        .then(() => {
          setSuccessMessage('Formular im Offline-Modus gespeichert!');
          setLoading(false);
          navigate('/home');
        })
        .catch((error) => {
          console.error('Fehler beim Speichern im Offline-Modus:', error);
          setLoading(false);
        });
    } else {
      const token = localStorage.getItem('token');
      
      // Unterschriften als Base64-String aus den Canvas-Elementen
      const signatureDataBetroffener = formData.unterschrift_verweigert || !sigCanvasBetroffener.current
      ? null
      : sigCanvasBetroffener.current.getTrimmedCanvas().toDataURL('image/png');

    const signatureDataBeamter = !sigCanvasBeamter.current
      ? null
      : sigCanvasBeamter.current.getTrimmedCanvas().toDataURL('image/png');

      axios.post(
        `${process.env.REACT_APP_API_URL}/api/forms/deb`,
        { ...formData, 
        unterschrift_betroffener: signatureDataBetroffener, 
        unterschrift_beamter: signatureDataBeamter, 
        quittung: formData.quittung_art },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setSuccessMessage('Formular erfolgreich erstellt! Weiterleitung...');
        setLoading(false); 

        const storedUser = localStorage.getItem('user');
        const user = storedUser ? JSON.parse(storedUser) : null;

        setTimeout(() => {
          // Zeige Passwort oder QR-Code Modal
          if (formData.quittung_art === 'email') {
            const generatedPassword = generateRandomPassword();
            setPassword(generatedPassword);

            // PDF generieren
            const pdfBlob = generatePDF(formData, generatedPassword);

            // PDF per E-Mail senden (hier Backend-Integration)
            // sendEmailWithPDF(formData.email, pdfBlob, generatedPassword);

            setShowPasswordModal(true);  // Zeige das Passwort für die verschlüsselte PDF
          } else if (formData.quittung_art === 'qr_code') {
            setShowQRCodeModal(true);  // Zeige den QR-Code an
          } else {
            handleNavigation();
          }
        }, 1500);
      })
      .catch((error) => {
        console.error('Fehler beim Erstellen des Formulars:', error);
        setLoading(false);
      });
    }
  };

  return (
    <div className="container mt-5">
      <Card className="mx-auto" style={{ maxWidth: '1000px' }}>
        <Card.Header>
          <h2 className="text-center">Formular DEB erstellen</h2>
        </Card.Header>
        <Card.Body>
          <ProgressBar now={(currentStep / 5) * 100} className="mb-4" />

          {successMessage && (
            <Alert variant="success" className="text-center">
              {successMessage}
            </Alert>
          )}
          
          <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Tipps zum Ausfüllen des Formulars</Accordion.Header>
          <Accordion.Body>
            Hier sind einige Tipps, wie du das Formular korrekt ausfüllst:
            <ul>
              <li><strong>Fahrer Name:</strong> Trage den vollständigen Namen des Fahrers ein.</li>
              <li><strong>Kennzeichen:</strong> Achte darauf, dass das Kennzeichen korrekt ist.</li>
              <li><strong>Verstoß Art:</strong> Wähle die richtige Verstoß Art aus den Vorlagen aus oder gib sie manuell ein.</li>
              <li><strong>Unterschrift:</strong> Stelle sicher, dass die Unterschrift korrekt erfasst ist oder wähle "Unterschrift verweigert".</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
          
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <div>
                <h3>Schritt 1: Tatort, Tatmittel und Tatvorwurf</h3>
                
                <Row>
                
                <Col md={5}>
  <Form.Group className="mb-3 w-100">
    <Form.Label>PLZ und Tatort</Form.Label>
    <InputGroup>
      <Form.Control
        type="text"
        name="tatort_plz"
        value={formData.tatort_plz}
        onChange={(e) => {
          const regex = /^[0-9]{0,5}$/;
          if (regex.test(e.target.value)) {
            setFormData({ ...formData, tatort_plz: e.target.value });
          }
        }}
        placeholder="PLZ"
        style={{ flex: 1 }} 
        required
      />
      <Form.Control
        type="text"
        name="tatort_ort"
        value={formData.tatort_ort}
        onChange={(e) => setFormData({ ...formData, tatort_ort: e.target.value })}
        placeholder="Ort"
        style={{ flex: 2 }} 
        required
      />
      <Button
        variant="secondary"
        className="btn-sm"
        onClick={handleLocationButtonClick}
      >
        GPS
      </Button>
    </InputGroup>
    <Form.Control.Feedback type="invalid">
      Bitte gib die PLZ und den Ort ein.
    </Form.Control.Feedback>
  </Form.Group>
</Col>

  
        <Col md={4}>
  <Form.Group className="mb-3 w-100">
    <Form.Label>Straße und Hausnummer</Form.Label>
    <InputGroup>
      <Form.Control
        type="text"
        name="tatort_strasse"
        value={formData.tatort_strasse}
        onChange={(e) => setFormData({ ...formData, tatort_strasse: e.target.value })}
        placeholder="Straße"
        style={{ flex: 2 }} 
        required
      />
      <Form.Control
        type="text"
        name="tatort_stasse_nr"
        value={formData.tatort_stasse_nr}
        onChange={(e) => setFormData({ ...formData, tatort_stasse_nr: e.target.value })}
        placeholder="Nr."
        style={{ flex: 1 }} 
        required
      />
    </InputGroup>
    <Form.Control.Feedback type="invalid">
      Bitte gib die Straße und Hausnummer ein.
    </Form.Control.Feedback>
  </Form.Group>
</Col>

          
          <Col md={3}>
          <Form.Group className="mb-3 w-100">
            <Form.Label>Zusatzinfos</Form.Label>
            <Form.Control
              type="text"
              name="tatort_zusatz"
              value={formData.tatort_zusatz}
              onChange={(e) => setFormData({ ...formData, tatort_zusatz: e.target.value })}
              placeholder="Mehr Infos zum Tatort..."
              required
            />
            <Form.Control.Feedback type="invalid">
              Bitte gib weitere Informationen ein.
            </Form.Control.Feedback>
          </Form.Group>
              </Col>
          
      </Row>

      <Row>
        <Col md={2}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Tattag</Form.Label>
                  <Form.Control
        type="date"
        name="tattag"
        value={formData.tattag}
        onChange={(e) => setFormData({ ...formData, tattag: e.target.value })}
        defaultValue={new Date().toISOString().split('T')[0]}
        required
      />
      <Form.Control.Feedback type="invalid">
        Bitte gib den Tattag ein.
      </Form.Control.Feedback>
                </Form.Group>
        </Col>
        <Col md={2}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Tatzeit</Form.Label>
                  <Form.Control
        type="time"
        name="tatzeit"
        value={formData.tatzeit}
        onChange={(e) => setFormData({ ...formData, tatzeit: e.target.value })}
        defaultValue={new Date().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
        required
      />
      <Form.Control.Feedback type="invalid">
        Bitte gib die Tatzeit ein.
      </Form.Control.Feedback>
                </Form.Group>
                        </Col>
                 <Col md={4}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Kennzeichen</Form.Label>
                  <Form.Control
                    type="text"
                    name="kennzeichen"
                    value={formData.kennzeichen}
                    onChange={handleKennzeichenChange}
                    placeholder="Kennzeichen eingeben"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Bitte gib das Kennzeichen an.</Form.Control.Feedback>
                </Form.Group>
                </Col>
                  <Col md={4}>
      <Form.Group className="mb-3 w-100">
                  <Form.Label>Hersteller</Form.Label>
                  <Form.Select
                    name="hersteller"
                    value={formData.hersteller}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Bitte wählen...</option>
                    {autohersteller.map((hersteller, index) => (
                      <option key={index} value={hersteller}>
                        {hersteller}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Bitte wähle den Autohersteller aus.
                  </Form.Control.Feedback>
                </Form.Group>
</Col>
</Row>
<Row>
        <Col md={5}>
                <Form.Group className="mb-3 w-100">
                      <Form.Label>Tatbestandsnummer(n)</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="tbnr_tabnr"
                          value={formData.tbnr_tabnr}
                          onChange={(e) => setFormData({ ...formData, tbnr_tabnr: e.target.value })}
                          placeholder="Tatbestandsnummer(n) eingeben oder Vorlagen verwenden"
                          required
                        />
                        <Button
                          variant="primary"
                          className="btn-sm"
                          onClick={() => setTatbestandsnummerModalShow(true)}
                        >
                          Vorlagen
                        </Button>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">
                        Bitte gib die Tatbestandsnummer(n) ein.
                      </Form.Control.Feedback>
                    </Form.Group>
</Col>

<Col md={7}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Konkretisierung</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    name="konkretisierung" 
                    value={formData.konkretisierung} 
                    onChange={handleChange} 
                  />
                </Form.Group>
      </Col>
</Row>

<Row>
      <Col md={2}>
      <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="behinderung" 
                    label="Behinderung"
                    checked={formData.behinderung} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
                                
      <Col md={2}>
      <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="gefährdung" 
                    label="Gefährdung"
                    checked={formData.gefährdung} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
      </Row>

<Row>
    <Col md={3}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Erlaubter Wert</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="erl_wert" 
                    value={formData.erl_wert} 
                    onChange={handleChange} 
                  />
                </Form.Group>
</Col>
<Col md={3}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Vorwerfbarer Wert</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="vorw_wert" 
                    value={formData.vorw_wert} 
                    onChange={handleChange} 
                  />
                </Form.Group>
</Col>              
      <Col md={3}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Euro</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="euro" 
                    value={formData.euro} 
                    onChange={handleChange} 
                  />
                </Form.Group>
</Col>
      <Col md={3}>
                <Form.Group className="mb-3 w-100">
                  <Form.Label>Halterauskunft (HA)</Form.Label>
                  <Form.Select
                    name="ha"
                    value={formData.ha}
                    onChange={handleChange}
                  >
                    <option value="">(Standard: Kennzeichen nach StVZO)</option>
                    {haOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Bitte wähle die Halterauskunft aus.
                  </Form.Control.Feedback>
                </Form.Group>
      </Col>
      </Row>
      
<Row className="align-items-center">
  <Col md={6}>
    <Form.Group className="mb-3 w-100">
      <Form.Label>VK</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          name="vk"
          value={formData.vk}
          onChange={(e) => setFormData({ ...formData, vk: e.target.value })}
          placeholder="VK eingeben oder Vorlagen verwenden"
          required
        />
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => setVkModalShow(true)}
        >
          Vorlagen
        </Button>
        <Form.Control.Feedback type="invalid">
          Bitte gib die VK ein.
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  </Col>

  <Col md={6}>
    <Form.Group className="mb-3 w-100">
      <Form.Label>Beweismittel</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          name="beweismittel"
          value={formData.beweismittel}
          onChange={(e) => setFormData({ ...formData, beweismittel: e.target.value })}
          placeholder="Beweismittel eingeben oder Vorlagen verwenden"
          required
        />
        <Button
          variant="primary"
          className="btn-sm"
          onClick={() => setBeweismittelModalShow(true)}
        >
          Vorlagen
        </Button>
        <Form.Control.Feedback type="invalid">
          Bitte gib das Beweismittel ein.
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  </Col>
</Row>

      <Row>
  <Col md={10}>
    <Form.Group className="mb-3 w-100">
      <Form.Label>Ergänzungen zum Tatvorwurf</Form.Label>
      <Form.Control 
        as="textarea" 
        name="ergänzungen_tatvorwurf" 
        value={formData.ergänzungen_tatvorwurf} 
        onChange={handleChange} 
        placeholder="Eigene Ergänzungen zum Tatvorwurf eingeben und/oder Beweismittel (Fotos, Dateien) einreichen"
        required
      />
      <Form.Control.Feedback type="invalid">
        Bitte gib zusätzliche Beweismittel ein und/oder füge extra Fotos oder Dateien hinzu.
      </Form.Control.Feedback>
    </Form.Group>
  </Col>
  <Col md={2} className="d-flex align-items-end">
    <Button variant="primary" onClick={handleOpenBeweismittelModal} className="w-100">
      Beweismittel hinzufügen
    </Button>
  </Col>
</Row>
              </div>
            )}
            {currentStep === 2 && (
  <div>
  <Row>
                <Col>
    <h3>
          Schritt 2: Personendaten <FontAwesomeIcon icon={faCamera} onClick={handleOpenAusweisModal} style={{ cursor: 'pointer' }} />


        </h3>
</Col>
              </Row>
              {/* Felder für Personendaten */}
      <Row>
                  <Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label>Vorname</Form.Label>
      <Form.Control 
        type="text" 
        name="vorname" 
        value={formData.vorname} 
        onChange={(e) => setFormData({ ...formData, vorname: e.target.value })}
                      required
                    />
      <Form.Control.Feedback type="invalid">Bitte gib den Vornamen an.</Form.Control.Feedback>
    </Form.Group>
      </Col>
      <Col md={6}>
      <Form.Group className="mb-3">
      <Form.Label>Geschlecht</Form.Label>
      <Form.Select 
        name="geschlecht" 
        value={formData.geschlecht} 
        onChange={(e) => setFormData({ ...formData, geschlecht: e.target.value })}
                      required
                    >
        <option value="M">Männlich</option>
        <option value="W">Weiblich</option>
        <option value="D">Divers</option>
      </Form.Select>
      <Form.Control.Feedback type="invalid">Bitte wähle ein Geschlecht aus.</Form.Control.Feedback>
    </Form.Group>
      </Col>
      </Row>
      <Row>
                  <Col md={6}>
    
    <Form.Group className="mb-3">
      <Form.Label>Familienname</Form.Label>
      <Form.Control 
        type="text" 
        name="familienname" 
        value={formData.familienname} 
        onChange={(e) => setFormData({ ...formData, familienname: e.target.value })}
                      required
                    />
      <Form.Control.Feedback type="invalid">Bitte gib den Familiennamen an.</Form.Control.Feedback>
    </Form.Group>

          </Col>
                  <Col md={6}>
  <Form.Group className="mb-3">
    <Form.Label>Geburtsname</Form.Label>
    <InputGroup>
      <Button
        variant="outline-secondary"
        onClick={() => setFormData({ ...formData, geburtsname: formData.familienname })}
        style={{ zIndex: 1 }} // Stelle sicher, dass der Button über dem Feld liegt
      >
        <FontAwesomeIcon icon={faExchangeAlt} />
      </Button>
      <Form.Control 
        type="text" 
        name="geburtsname" 
        value={formData.geburtsname} 
        onChange={handleChange} 
        style={{ marginLeft: '-1px' }} // Schiebe das Textfeld nach links, um die Lücke zu füllen
      />
    </InputGroup>
  </Form.Group>
</Col>


      </Row>
      <Row>
                  <Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label>Geburtsdatum</Form.Label>
      <Form.Control 
        type="date" 
        name="geburtsdatum" 
        value={formData.geburtsdatum || '1990-01-01'} 
        onChange={(e) => setFormData({ ...formData, geburtsdatum: e.target.value })}
                      required
                    />
      <Form.Control.Feedback type="invalid">Bitte gib das Geburtsdatum an.</Form.Control.Feedback>
    </Form.Group>
</Col>
<Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label>Geburtsort</Form.Label>
      <Form.Control 
        type="text" 
        name="geburtsort" 
        value={formData.geburtsort} 
        onChange={handleChange} 
      />
    </Form.Group>
</Col>
      </Row>
      <Row>
<Col md={12}>
    <Form.Group className="mb-3">
      <Form.Label>Straße und Hausnummer</Form.Label>
      <Form.Control 
        type="text" 
        name="strasse_hausnummer" 
        value={formData.strasse_hausnummer} 
        onChange={(e) => setFormData({ ...formData, strasse_hausnummer: e.target.value })}
                    />
    </Form.Group>
</Col>
      </Row>
      <Row>
<Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label>Postleitzahl</Form.Label>
      <Form.Control 
        type="text" 
        name="postleitzahl" 
        value={formData.postleitzahl} 
        onChange={(e) => setFormData({ ...formData, postleitzahl: e.target.value })}
                    />
    </Form.Group>
</Col>
<Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label>Wohnort</Form.Label>
      <Form.Control 
        type="text" 
        name="wohnort" 
        value={formData.wohnort} 
        onChange={(e) => setFormData({ ...formData, wohnort: e.target.value })}
                    />
    </Form.Group>
</Col>
      </Row>      
  </div>
)}
{/* Schritt 3: Zeuge */}
            {currentStep === 3 && (
              <div>
                <h3>Schritt 3: Zeuge</h3>
                <Form.Group className="mb-3">
                  <Form.Label>Name der Zeugin oder des Zeugen</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="name_zeuge" 
                    value={formData.name_zeuge} 
                    onChange={handleChange} 
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Anschrift der Zeugin oder des Zeugen</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    name="anschrift_zeuge" 
                    value={formData.anschrift_zeuge} 
                    onChange={handleChange} 
                  />
                </Form.Group>
              </div>
            )}

            {/* Schritt 4: Anhörung, Belehrungen und Unterschriften */}
            {currentStep === 4 && (
              <div>
                <h3>Schritt 4: Anhörung, Belehrungen und Unterschriften</h3>
                <Row>
<Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="belehrung1" 
                    label="Ich wurde darauf hingewiesen, dass es mir freisteht, mich zum Vorwurf zu äußern oder nicht auszusagen"
                    checked={formData.belehrung1} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
                </Row>
                
                {!formData.anhörung1 && (
  <Row>
    <Col md={12}>
      <Form.Group className="mb-3">
        <Form.Label>Anhörung</Form.Label>
        <Form.Control 
          as="textarea" 
          name="anhörung" 
          value={formData.anhörung} 
          onChange={handleChange}
          required={!formData.anhörung1} // Required nur wenn Checkbox nicht ausgewählt
        />
      </Form.Group>
    </Col>
  </Row>
)}

<Row>
  <Col md={4}>
    <Form.Group className="mb-3">
      <Form.Check 
        type="checkbox" 
        name="anhörung1" 
        label="Anhörung nicht durchgeführt"
        checked={formData.anhörung1} 
        onChange={handleChange} 
      />
    </Form.Group>
  </Col>
<Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="verwarnung_abgelehnt" 
                    label="Verwarnung abgelehnt"
                    checked={formData.verwarnung_abgelehnt} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
                </Row>
                
                <Row>
<Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="belehrung2" 
                    label="Ich gebe den Verstoß zu"
                    checked={formData.belehrung2} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
<Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="belehrung3" 
                    label="Ich gebe den Verstoß nicht zu"
                    checked={formData.belehrung3} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
<Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="belehrung4" 
                    label="Ich möchte mich schriftlich äußern"
                    checked={formData.belehrung4} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
                </Row>

                {/* Unterschrift Betroffener */}
                <Form.Group className="mb-3">
                  <Form.Label>Unterschrift Betroffener</Form.Label>
                  <div 
                  className="signature-container"
                  style={{ 
                  border: '1px solid #ccc', 
                  width: '100%', height: '150px', 
                  maxWidth: '500px', 
                  margin: '0 auto',
                  pointerEvents: formData.unterschrift_verweigert ? 'none' : 'auto', // Deaktiviert das Unterschriftenfeld
                  opacity: formData.unterschrift_verweigert ? 0.5 : 1 // Macht das Unterschriftenfeld halbtransparent, wenn es deaktiviert ist
                  }}
                  >
                    <SignatureCanvas 
                      ref={sigCanvasBetroffener}
                      penColor="black"
                      canvasProps={{
                        className: 'signature-canvas',
                        width: 500,
                        height: 150,
                        willReadFrequently: true // Diese Eigenschaft hinzufügen
                      }}
                      onEnd={saveSignatureBetroffener}
/>
                  </div>
                  <Button
                    variant="secondary" 
                    className="mt-2" 
                    onClick={clearSignatureBetroffener} 
                    disabled={formData.unterschrift_verweigert}
                  >
                    Unterschrift löschen
                  </Button>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox" 
                    name="unterschrift_verweigert" 
                    label="Unterschrift verweigert" 
                    checked={formData.unterschrift_verweigert}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            )}

            {/* Schritt 5: Unterschrift Beamter */}
            {currentStep === 5 && (
              <div>
                <h3>Schritt 5: Unterschrift Beamter</h3>
                <Row>
                <Col md={8}>
                <Form.Group className="mb-3">
                  <Form.Label>Bemerkungen</Form.Label>
                  <Form.Control 
                    as="textarea" 
                    name="bemerkungen" 
                    value={formData.bemerkungen} 
                    onChange={handleChange} 
                  />
                </Form.Group>
                </Col>
                <Col md={4}>
                {/* Quittungsabfrage */}
                <Form.Group className="mb-3">
                  <Form.Label>Möchten Sie eine Quittung erhalten?</Form.Label>
                  <Form.Select 
                    name="quittung_art" 
                    value={formData.quittung_art} 
                    onChange={handleChange}
                    required
                  >
                    <option value="">Bitte wählen...</option>
                    <option value="email">Per E-Mail</option>
                    <option value="qr_code">Per QR-Code</option>
                    <option value="keine_quittung">Keine Quittung</option>
                  </Form.Select>
                </Form.Group>

                {formData.quittung_art === 'email' && (
                  <Form.Group className="mb-3">
                    <Form.Label>E-Mail-Adresse</Form.Label>
                    <Form.Control 
                      type="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                      required={formData.quittung_art === 'email'}
                    />
                  </Form.Group>
                )}
                </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Unterschrift Beamter</Form.Label>
                  <div className="signature-container" style={{ border: '1px solid #ccc', width: '100%', height: '150px', maxWidth: '500px', margin: '0 auto' }}>
                    <SignatureCanvas 
  ref={sigCanvasBeamter}
  penColor="black"
  canvasProps={{
    className: 'signature-canvas',
    width: 500,
    height: 150,
    willReadFrequently: true // Diese Eigenschaft hinzufügen
  }}
  onEnd={saveSignatureBeamter}
/>

                  </div>
                  <Button variant="secondary" className="mt-2" onClick={clearSignatureBeamter}>
                    Unterschrift löschen
                  </Button>
                </Form.Group>
              </div>
            )}
          </Form>
        </Card.Body>
        <Card.Footer>
	<Row className="d-flex justify-content-between">
            <Col className="d-flex justify-content-start">
              <Button variant="secondary" onClick={handlePrev}>
                Zurück
              </Button>
            </Col>
            <Col className="d-flex justify-content-end">
              {currentStep < 5 ? (
                <Button variant="primary" onClick={handleNext}>
                  Weiter
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Formular abgeben
                </Button>
              )}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
	{/* Abbrechen Button unterhalb der Card */}
      <div className="text-center mt-3">
        <Button variant="danger" onClick={handleCancel}>
          Abbrechen
        </Button>
      </div>
      
       {/* Tatbestandsnummer Modal */}
      <TatbestandsnummerModal
                  show={tatbestandsnummerModalShow}
                  handleClose={() => setTatbestandsnummerModalShow(false)}
                  handleSave={handleTatbestandsnummerSave}
                  selectedKeys={selectedTatbestandsnummern}
                  username={loggedInUsername}
                />
      
      {/* VK Auswahl Modal */}
      <VKSelectionModal
        show={vkModalShow}
        handleClose={() => setVkModalShow(false)}
        handleSave={handleVKSave}
      />
      
      {/* Beweismittel Auswahl Modal */}
<BeweismittelSelectionModal
  show={beweismittelModalShow}
  handleClose={() => setBeweismittelModalShow(false)}
  handleSave={handleBeweismittelSave}
/>
      
      {/* BeweismittelModal einfügen */}
      <BeweismittelModal
        show={showBeweismittelModal}
        handleClose={handleCloseBeweismittelModal}
        handleSave={handleSaveBeweismittel}
      />
      
      {/* Modal für Passwort-Anzeige */}
<Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Passwort für PDF</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <h3>{password}</h3> {/* Das zufällig generierte Passwort */}
    <p>Bitte teilen Sie dieses Passwort dem Betroffenen mit.</p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleNavigation}>Schließen und Weiter</Button>
  </Modal.Footer>
</Modal>

{/* Modal für QR-Code-Anzeige */}
<Modal show={showQRCodeModal} onHide={() => setShowQRCodeModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>QR-Code für die Quittung</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {generateQRCode()} {/* QR-Code wird hier angezeigt */}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleNavigation}>Schließen und Weiter</Button>
  </Modal.Footer>
</Modal>

      
      {/* Modal für Bestätigung */}
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Formular abgeben</Modal.Title>
        </Modal.Header>
        <Modal.Body>Bist du sicher, dass das Formular korrekt ausgefüllt wurde und abgegeben werden soll?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>Abbrechen</Button>
          <Button variant="primary" onClick={handleSubmit}>Ja, abgeben</Button>
        </Modal.Footer>
      </Modal>
      
      <AusweisModal
        showModal={showAusweisModal}   // Sichtbarkeit des Modals
        handleCloseAusweisModal={handleCloseAusweisModal}  // Funktion zum Schließen
        setFormData={setFormData}  // Formular-Daten aktualisieren
        formData={formData}        // Aktuelle Formulardaten
      />
      
    </div>
  );
}

export default FormularDEBErstellen;
