import { jwtDecode } from "jwt-decode";

export const getLoggedInUsername = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedToken = jwtDecode(token); // Default-Import korrekt verwenden
      return decodedToken.username; // Benutzername aus dem Token extrahieren
    } catch (error) {
      console.error('Fehler beim Dekodieren des Tokens:', error);
      return null;
    }
  }
  return null;
};
