import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header>
          <h3>Admin Panel</h3>
        </Card.Header>
        <Card.Body>
          <Button onClick={() => navigate('/admin/users')} variant="primary">Benutzerverwaltung</Button>
          <Button onClick={() => navigate('/admin/settings')} variant="secondary" className="ms-3">Systemeinstellungen</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminPanel;
