import React, { useState } from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Tesseract from 'tesseract.js';
/* global cv */

const AusweisModal = ({ showModal, handleCloseAusweisModal, setFormData, formData }) => {
  const [ocrProcessing, setOcrProcessing] = useState(false);
  const [ocrErrorMessage, setOcrErrorMessage] = useState('');
  const [isFrontSideCaptured, setIsFrontSideCaptured] = useState(false); // State for front side capture
  const [frontImageData, setFrontImageData] = useState(null); // Front side image data
  const [backImageData, setBackImageData] = useState(null);  // Back side image data

  // Image resizing using OpenCV
  const resizeImage = (src, width, height) => {
    const dsize = new cv.Size(width, height);
    const resized = new cv.Mat();
    cv.resize(src, resized, dsize, 0, 0, cv.INTER_AREA);
    return resized;
  };

  // Noise removal using OpenCV
  const removeNoise = (image) => {
    const kernel = cv.getStructuringElement(cv.MORPH_RECT, new cv.Size(3, 3));
    cv.morphologyEx(image, image, cv.MORPH_OPEN, kernel); // Opening (Erosion followed by Dilation)
    return image;
  };

  // Preprocess image using OpenCV
  const preprocessImage = (imageElement) => {
    const src = cv.imread(imageElement);
    const gray = new cv.Mat();
    cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0);

    // Resize image
    const resized = resizeImage(gray, 640, 480);

    // Apply Gaussian Blur
    const blurred = new cv.Mat();
    cv.GaussianBlur(resized, blurred, new cv.Size(5, 5), 0, 0, cv.BORDER_DEFAULT);

    // Remove noise
    const noiseRemoved = removeNoise(blurred);

    // Apply binary thresholding
    const thresh = new cv.Mat();
    cv.threshold(noiseRemoved, thresh, 0, 255, cv.THRESH_BINARY + cv.THRESH_OTSU);

    return thresh;
  };

  // OCR processing with Tesseract
  const processImageWithOCR = (imageElement, isFrontSide) => {
    setOcrProcessing(true);
    const processedImage = preprocessImage(imageElement); // Preprocessed image using OpenCV

    // Convert OpenCV image to a Data URL that Tesseract can process
    const canvas = document.createElement('canvas');
    cv.imshow(canvas, processedImage);
    const dataURL = canvas.toDataURL('image/png');

    Tesseract.recognize(dataURL, 'deu', { logger: (m) => console.log(m) })
      .then(({ data: { text } }) => {
        console.log('Erkannter Text:', text);
        setOcrProcessing(false);
        if (isFrontSide) {
          mapFrontSideTextToFields(text);
          setIsFrontSideCaptured(true);
        } else {
          mapBackSideTextToFields(text);
          handleCloseAusweisModal(); // Close modal after processing both sides
        }
      })
      .catch((err) => {
        setOcrProcessing(false);
        console.error('Fehler während der Texterkennung:', err);
        setOcrErrorMessage('Texterkennung fehlgeschlagen. Bitte versuchen Sie es erneut.');
      });
  };

  // Map front side text to form fields
  const mapFrontSideTextToFields = (text) => {
    const vornameRegex = /Vorname:\s*([A-Za-zäöüß]+)/i;
    const familiennameRegex = /Familienname:\s*([A-Za-zäöüß]+)/i;
    const geburtsdatumRegex = /Geburtsdatum:\s*(\d{2}\.\d{2}\.\d{4})/i;

    const extractedData = {
      vorname: text.match(vornameRegex)?.[1] || '',
      familienname: text.match(familiennameRegex)?.[1] || '',
      geburtsdatum: text.match(geburtsdatumRegex)?.[1] || ''
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...extractedData,
    }));
  };

  // Map back side text to form fields
  const mapBackSideTextToFields = (text) => {
    const strasseRegex = /\b([A-ZÄÖÜ][a-zäöüß]+(?:straße|str\.?|weg|allee|platz|ring))\s(\d+[a-zA-Z]?)\b/i;
    const postleitzahlRegex = /\b\d{5}\b/;
    const wohnortRegex = /\b([A-ZÄÖÜ][a-zäöüß]+)\b/;

    const strasseMatch = text.match(strasseRegex);
    const extractedData = {
      strasse_hausnummer: strasseMatch ? `${strasseMatch[1]} ${strasseMatch[2]}` : '',
      postleitzahl: text.match(postleitzahlRegex)?.[0] || '',
      wohnort: text.match(wohnortRegex)?.[1] || ''
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      ...extractedData,
    }));
  };

  // Process OCR for both front and back images
  const processImagesWithOCR = () => {
    if (frontImageData && backImageData) {
      processImageWithOCR(frontImageData, true);  // Process front image first
      processImageWithOCR(backImageData, false);  // Process back image second
    } else {
      setOcrErrorMessage('Bitte sowohl die Vorder- als auch die Rückseite fotografieren.');
    }
  };

  // Handle image capture for front and back
  const handleCapture = (event, isFront) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = () => {
        if (isFront) {
          setFrontImageData(img);
          setIsFrontSideCaptured(true);
        } else {
          setBackImageData(img);
        }
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal show={showModal} onHide={handleCloseAusweisModal}>
      <Modal.Header closeButton>
        <Modal.Title>Ausweisdaten erfassen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Feedback und Status oben */}
        {ocrProcessing && <Alert variant="info">Texterkennung läuft...</Alert>}
        {ocrErrorMessage && <Alert variant="danger">{ocrErrorMessage}</Alert>}
        
        <div className="text-center">
          <p>Bitte zuerst die Vorderseite und dann die Rückseite des Ausweises fotografieren.</p>

          {/* Buttons untereinander angeordnet */}
          <div className="mb-3">
            <Button variant="outline-primary" onClick={() => document.getElementById('cameraInputFront').click()} className="w-100 mb-2">
              <FontAwesomeIcon icon={faCamera} /> Vorderseite fotografieren
            </Button>
            <input
              id="cameraInputFront"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => handleCapture(e, true)} // Capture front side
              style={{ display: 'none' }}
            />

            <Button variant="outline-secondary" onClick={() => document.getElementById('cameraInputBack').click()} className="w-100">
              <FontAwesomeIcon icon={faCamera} /> Rückseite fotografieren
            </Button>
            <input
              id="cameraInputBack"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(e) => handleCapture(e, false)} // Capture back side
              style={{ display: 'none' }}
            />
          </div>

          {/* Kleinere Darstellung der Bilder */}
          <div className="mb-3">
            {frontImageData && <img src={frontImageData.src} alt="Vorderseite" style={{ width: '100px', marginTop: '10px' }} />}
            {backImageData && <img src={backImageData.src} alt="Rückseite" style={{ width: '100px', marginTop: '10px' }} />}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={processImagesWithOCR} disabled={!frontImageData || !backImageData} className="w-100">
          Texterkennung starten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AusweisModal;
