import { useState } from 'react';
import axios from 'axios';

export const useGPSLocation = () => {
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            setLocationData(response.data);
          } catch (err) {
            setError(err);
          } finally {
            setLoading(false);
          }
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    } else {
      setError(new Error('Geolocation wird von diesem Browser nicht unterstützt.'));
    }
  };

  return { locationData, loading, error, fetchLocation };
};