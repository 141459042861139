import React, { useState, useEffect } from 'react';
import { Modal, Button, Tab, Tabs, Form, InputGroup } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

const TatbestandsnummerModal = ({ show, handleClose, handleSave, selectedKeys, username }) => {
  const [activeTab, setActiveTab] = useState('favoriten');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState(selectedKeys || []);
  const [favoriten, setFavoriten] = useState([]);
  const [tatbestandsnummern, setTatbestandsnummern] = useState({});
  
  // Abrufen der Favoriten und Tatbestandsnummern
  useEffect(() => {
    if (username) {
      // Abrufen der Favoriten aus dem Benutzerprofil
      axios.get(`/api/user-profile/${username}`)
        .then(response => {
          const profile = response.data;
          setFavoriten(profile.form_deb_favoriten || []);
        })
        .catch(error => console.error('Fehler beim Laden des Profils:', error));
    }

    // Abrufen der Tatbestandsnummern aus der Datenbank
    axios.get('/api/tatbestandsnummern')
      .then(response => {
        setTatbestandsnummern(response.data); // Speichern der Tatbestandsnummern nach Kategorien
      })
      .catch(error => console.error('Fehler beim Laden der Tatbestandsnummern:', error));
  }, [username]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const handleItemSelect = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.find((selected) => selected.key === item.key)) {
        return prevSelected.filter((selected) => selected.key !== item.key);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const filteredData = (tabKey) => {
    const items = tatbestandsnummern[tabKey] || [];
    return items.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.key.includes(searchTerm)
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Wähle Tatbestandsnummer(n)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Suche Tatbestandsnummer oder Titel"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {searchTerm && (
            <Button variant="outline-secondary" onClick={handleClearSearch}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </InputGroup>
        {searchTerm ? (
          <div>
            <strong>Suchergebnisse:</strong>
            {Object.keys(tatbestandsnummern).flatMap(tabKey =>
              filteredData(tabKey).map((item) => (
                <Form.Check
                  key={item.key}
                  type="checkbox"
                  label={`${item.key} - ${item.title}`}
                  checked={selectedItems.some((selected) => selected.key === item.key)}
                  onChange={() => handleItemSelect(item)}
                />
              ))
            )}
          </div>
        ) : (
          <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
            <Tab eventKey="favoriten" title="Favoriten">
              {favoriten.length === 0 ? (
                <p>Keine Favoriten gespeichert</p>
              ) : (
                favoriten.map((item) => (
                  <Form.Check
                    key={item.key}
                    type="checkbox"
                    label={`${item.key} - ${item.title}`}
                    checked={selectedItems.some((selected) => selected.key === item.key)}
                    onChange={() => handleItemSelect(item)}
                  />
                ))
              )}
            </Tab>
            {Object.keys(tatbestandsnummern).map((tabKey) => (
              <Tab eventKey={tabKey} title={tabKey} key={tabKey}>
                {filteredData(tabKey).map((item) => (
                  <Form.Check
                    key={item.key}
                    type="checkbox"
                    label={`${item.key} - ${item.title}`}
                    checked={selectedItems.some((selected) => selected.key === item.key)}
                    onChange={() => handleItemSelect(item)}
                  />
                ))}
              </Tab>
            ))}
          </Tabs>
        )}
        <div>
          <strong>Ausgewählte Tatbestandsnummern:</strong>
          <ul>
            {selectedItems.map((item) => (
              <li key={item.key}>{item.key} - {item.title}</li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button variant="primary" onClick={() => {
          handleSave(selectedItems);
          handleClose();
        }}>
          Übernehmen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TatbestandsnummerModal;
