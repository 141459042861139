import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Form, Alert, Spinner, ListGroup } from 'react-bootstrap';
import './Login.css'; // CSS für Flexbox und Breitenanpassung

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recentUsers, setRecentUsers] = useState([]);
  const passwordInputRef = useRef(null); // Ref für das Passwortfeld
  const navigate = useNavigate();

  useEffect(() => {
    // Letzte Benutzernamen aus dem localStorage laden
    const storedUsers = JSON.parse(localStorage.getItem('recentUsers')) || [];
    setRecentUsers(storedUsers);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { username, password })
      .then(response => {
        setLoading(false);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('token', response.data.token);

        // Benutzer in der Liste der letzten Benutzernamen speichern
        const updatedUsers = [...recentUsers.filter(user => user !== username), username].slice(-3);
        localStorage.setItem('recentUsers', JSON.stringify(updatedUsers));

        navigate('/home');
      })
      .catch(() => {
        setLoading(false);
        setError('Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Daten.');
      });
  };

  const handleRecentUserClick = (name) => {
    setUsername(name); // Benutzername in das Eingabefeld übernehmen
    passwordInputRef.current.focus(); // Den Cursor in das Passwortfeld setzen
  };

  return (
    <div className="login-container">
      <Card className="login-card">
        <Card.Body>
          <h3 className="text-center mb-4">Anmeldung</h3>

          {/* Liste der letzten Benutzernamen */}
          {recentUsers.length > 0 && (
            <div className="mb-4">
              <p className="text-center">Letzte Anmeldungen:</p>
              <ListGroup horizontal className="justify-content-center">
                {recentUsers.map((user, index) => (
                  <ListGroup.Item
                    action
                    variant="light"
                    key={index}
                    onClick={() => handleRecentUserClick(user)}
                  >
                    {user}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}

          {error && (
            <Alert variant="danger">
              {error}
            </Alert>
          )}

          {loading && (
            <div className="text-center mb-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Anmeldung läuft...</span>
              </Spinner>
            </div>
          )}

          <Form onSubmit={handleLogin}>
            <div className="form-floating mb-3">
              <Form.Control
                type="text"
                id="username"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <Form.Label htmlFor="username">Benutzername</Form.Label>
            </div>

            <div className="form-floating mb-3">
              <Form.Control
                type="password"
                id="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                ref={passwordInputRef} // Ref für das Passwortfeld
              />
              <Form.Label htmlFor="password">Passwort</Form.Label>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100"
              disabled={loading}
            >
              {loading ? 'Anmeldung läuft...' : 'Anmelden'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Login;
